<template>
  <hit-modal popup-size="large" :cancellable="false">
    <vue-pdf-app
      v-if="bufferToDisplay"
      :config="pdfViewerConfig"
      :pdf="bufferToDisplay"
      :file-name="fileNameToDisplay"
      style="height: 85vh"
      page-scale="page-height"
    />
  </hit-modal>
</template>

<script>
import VuePdfApp from 'vue3-pdf-app';
import {HitModal} from '@hit/components/src';
import {StorageService} from '@hit/base/src/api/data/StorageService';

export default {
  name: 'HitPdfPreviewModal',
  components: {
    HitModal,
    VuePdfApp,
  },
  props: {
    /**
     * PDF stream we want to display in the viewer
     */
    pdfFileBuffer: {
      type: ArrayBuffer,
      required: false,
      default: null,
    },

    /**
     * Name of the PDF if the user downloads it via the toolbar
     */
    pdfFileName: {
      type: String,
      required: false,
      default: null,
    },

    /**
     * ID of the document we want to display in the viewer
     */
    documentId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      bufferToDisplay: null,
      fileNameToDisplay: null,
      pdfViewerConfig: {
        toolbar: {
          toolbarViewerRight: {
            viewBookmark: false,
            openFile: false,
          },
        },
      },
    };
  },

  beforeMount() {
    if (this.pdfFileBuffer && this.pdfFileName) {
      this.bufferToDisplay = this.pdfFileBuffer;
      this.fileNameToDisplay = this.pdfFileName;
    } else if (this.documentId) {
      StorageService.fetchFileBuffer(this.documentId).then((res) => {
        this.bufferToDisplay = res?.fileBuffer;
        this.fileNameToDisplay = res?.fileName;
      });
    } else {
      throw Error(
        'You either need to pass the documentId or to pass the file buffer and name'
      );
    }
  },
};
</script>
