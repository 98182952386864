import {MultiLanguageDesignationModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import yaml from '@hit/components/src/utils/yaml/yaml';
import {AtRule} from 'postcss';
import {isValidFormDefYaml} from 'hit-online-web-ui/src/views/form/FormUtils';

export default class FormDefinitionNew extends MultiLanguageDesignationModel {
  constructor({
    id,
    number = '',
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    active = true,
    definition = undefined,
    creationRoles = undefined,
    supervisorGroupRoles = undefined,
    supervisorLinkedRoles = undefined,
  }) {
    super({
      id,
      number,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.active = active;
    this.definition = definition ? yaml.toJson(definition) : undefined;
    this.validSyntax = definition ? isValidFormDefYaml(definition) : undefined;
    this.creationRoles = creationRoles;
    this.supervisorGroupRoles = supervisorGroupRoles;
    this.supervisorLinkedRoles = supervisorLinkedRoles;
  }

  static get fkName() {
    return 'form_definition_id';
  }
  static get apiRoute() {
    return 'form_definition';
  }
  static get listPageRoute() {
    return 'formDefinitionList';
  }
  static get detailPageRoute() {
    return 'standardFormDefinitionItem';
  }
  static get createInitColumns() {
    return {};
  }
  get fkName() {
    return FormDefinitionNew.fkName;
  }
  get apiRoute() {
    return FormDefinitionNew.apiRoute;
  }
  get detailPageRoute() {
    return FormDefinitionNew.detailPageRoute;
  }
  get listPageRoute() {
    return FormDefinitionNew.listPageRoute;
  }

  static get listDisplayAttrs() {
    return this.getAttributes(
      [
        this.attributeUserLocale('designation'),
        this.attributeCompanyLocale('designation'),
        'active',
        'validSyntax',
      ],
      ['definition']
    );
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      [
        this.attributeUserLocale('designation'),
        this.attributeCompanyLocale('designation'),
      ],
      ['definition']
    );
  }

  static get joinAttrsKey() {
    return [
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
    ];
  }

  static get joinFilterColumns() {
    return [
      this.attributeUserLocale('designation', true),
      this.attributeCompanyLocale('designation', true),
    ];
  }

  static get allAttributes() {
    return {
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.common.active',
          maxWidth: '1fr',
        },
      }),
      creationRoles: new HitContainerAttribute(ATTRIBUTE_TYPES.ARRAY, {
        column: 'creation_roles',
        tableProperties: {
          header: 'hit-app.form.creation-roles',
        },
      }),
      supervisorGroupRoles: new HitContainerAttribute(ATTRIBUTE_TYPES.ARRAY, {
        column: 'supervisor_group_roles',
        tableProperties: {
          header: 'hit-app.form.supervisor-group-roles',
        },
      }),
      supervisorLinkedRoles: new HitContainerAttribute(ATTRIBUTE_TYPES.ARRAY, {
        column: 'supervisor_linked_roles',
        tableProperties: {
          header: 'hit-app.form.supervisor-linked-roles',
        },
      }),
      mail: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'mail_recipients',
        tableProperties: {
          header: 'hit-base.common.mail',
        },
      }),
      definition: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.data',
        },
      }),
      validSyntax: new HitContainerAttribute(ATTRIBUTE_TYPES.NOT_FETCHED, {
        tableProperties: {
          header: 'hit-app.form.syntax',
        },
      }),
    };
  }
}
