import {DataService, useConfigurationStore} from '@hit/base/src';
import {HitUtils} from '../hit/HitUtils';
import {HitDateUtils} from '../date/HitDateUtils';

export class HitActionUtils extends HitUtils {
  /**
   * Different status for an action
   */
  static Status = Object.freeze({
    OPENED: 'OPENED',
    ASSIGNED: 'ASSIGNED',
    CLOSED: 'CLOSED',
  });

  /**
   * Different operations that can be registered in the history
   */
  static Operation = Object.freeze({
    CREATE: 'CREATE',
    ASSIGN: 'ASSIGN',
    UNASSIGN: 'UNASSIGN',
    CHANGE_ASSIGNEE: 'CHANGE_ASSIGNEE',
    RENAME: 'RENAME',
    ADD_TAG: 'ADD_TAG',
    REMOVE_TAG: 'REMOVE_TAG',
    MESSAGE: 'MESSAGE',
    START: 'START',
    STOP: 'STOP',
    CLOSE: 'CLOSE',
    REOPEN: 'REOPEN',
    CHANGE_DUE_DATE: 'CHANGE_DUE_DATE',
  });

  /**
   * Generates a random number with 6 digits
   */
  static generateRandomNo() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  /**
   * After meeting of 10/03/2025 we decided that the default due date is null
   * We still keep the method if we decide in the future to change the default due date depending on other criteria
   */
  static getDefaultDueDate() {
    return null;
  }

  /**
   * @param id - ID that we want to use for the new action
   * @param data - Data to initialize some columns of the action during the creation
   */
  static create(id, data) {
    const {dueDate, ...dataWithoutDueDate} = data || {};
    const dueDateValue = dueDate ? dueDate : this.getDefaultDueDate();
    return DataService.create('action', {
      ...dataWithoutDueDate,
      id: id,
      no: this.generateRandomNo(),
      designation: data?.designation ?? 'NEW ACTION',
      status: data?.status ? data.status : this.Status.OPENED,
      due_date: dueDateValue,
    });
  }

  /**
   * Converts the duration string returned by the database in milliseconds
   * @param d - duration string returned by the database
   * @returns {number}
   */
  static durationToMs(d) {
    if (!d) return 0;
    const pattern = /(?:(\d+) days )?(?:(\d+):(\d+):(\d+)(?:\.(\d+))?)?/;
    const match = d.match(pattern);
    if (!match) {
      throw new Error('Invalid duration format');
    }
    const day = match[1] ? parseInt(match[1], 10) : 0;
    const h = match[2] ? parseInt(match[2], 10) : 0;
    const m = match[3] ? parseInt(match[3], 10) : 0;
    const s = match[4] ? parseInt(match[4], 10) : 0;
    const sMs = match[5] ? parseInt(match[5], 10) : 0;
    const ms = Math.round(sMs / 1000);
    return (day * 24 * 3600 + h * 3600 + m * 60 + s) * 1000 + ms;
  }

  /**
   * Converts the number of milliseconds into a readable time format
   * @param ms - number of milliseconds
   * @returns {string}
   */
  static formatMs(ms) {
    const locale = useConfigurationStore().userLanguageSnakeCase;
    const h = Math.floor(ms / (1000 * 60 * 60))
      .toString()
      .padStart(2, '0');
    const m = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, '0');
    const s = Math.round((ms % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0');
    switch (locale) {
      case 'en_gb':
        return `${h}:${m}:${s}`;
      default:
        return `${h}h ${m}m ${s}s`;
    }
  }

  /**
   * Converts the duration returned by the database into a readbale time format
   * @param d - duration returned by the database
   * @returns {string}
   */
  static stringifyDuration(d) {
    return this.formatMs(this.durationToMs(d));
  }

  /**
   * Converts the interval into seconds
   */
  static durationToSeconds(duration) {
    if (!duration) return 0;

    const pattern = /^(\d{1,2}):(\d{2}):(\d{2})$/;
    const match = duration.match(pattern);

    if (!match) {
      throw new Error('Invalid duration format');
    }

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const seconds = parseInt(match[3], 10);

    return hours * 3600 + minutes * 60 + seconds; // Return total seconds
  }

  /**
   * Creates the text that will be displayed in the action history
   * @param log - object from the history record
   * @param t - translation function
   * @returns {*}
   */
  static generateHistoryText(log, t) {
    let config = {};
    switch (log.operation.id) {
      case HitActionUtils.Operation.MESSAGE:
        return log.message;
      case HitActionUtils.Operation.CHANGE_DUE_DATE:
        config['due_date'] = HitDateUtils.toShortDate(new Date(log.message));
        break;
      case HitActionUtils.Operation.ASSIGN:
        config['assignee'] = log.assignee.designation;
        break;
      case HitActionUtils.Operation.CHANGE_ASSIGNEE:
        config['assignee'] = log.assignee.designation;
        break;
    }
    return t(
      `hit-app.action.history-${log.operation.id
        .toLowerCase()
        .replaceAll('_', '-')}`,
      Object.keys(config).length > 0 ? config : log
    );
  }
}
