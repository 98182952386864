<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    use-custom-height
    class="hit-file-input"
  >
    <div
      v-if="!selectedFile && !readonly"
      class="flex flex-col p-5 items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg bg-panel cursor-pointer"
      :style="{
        'border-color': isDragging ? '#1c64f2' : undefined,
        width: responsiveBreakpointXs ? '18.75rem' : '12.25rem',
      }"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <label>
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
          <hit-icon
            class="w-full h-full"
            icon="upload"
          />
          <p class="mb-2 text-sm text-panel-light">
            {{ t('hit-components.common.upload') }}
          </p>
        </div>
        <input
          ref="fileInput"
          type="file"
          class="hidden"
          @change="handleFileChange"
        >
      </label>
    </div>
    <div
      v-if="selectedFile"
      class="flex flex-col items-start justify-between p-2 w-full h-full border-2 border-gray-300 border-dashed rounded-lg"
      :style="{
        width: responsiveBreakpointXs ? '18.75rem' : '12.25rem',
      }"
    >
      <div
        v-if="!readonly"
        class="flex items-center justify-between w-full"
      >
        <div class="flex items-start">
          <svg
            v-if="upload_status"
            aria-hidden="true"
            class="inline w-4 h-4 mr-2 text-blue-500 animate-spin"
            viewBox="0 0 100 101"
            fill="none"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#1a56db"
            />
          </svg>
          <svg
            v-if="!upload_status"
            class="w-4 h-4 text-green-500"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"
            />
          </svg>
        </div>
        <div class="flex ml-auto w-4 h-4 mr-2 -mt-2">
          <button
            class="ml-2 items-center"
            type="button"
            title="Remove file"
            @click="remove()"
          >
            <hit-icon
              class="w-4 h-4"
              icon="trash"
            />
          </button>
        </div>
      </div>
      <div
        class="hit-input-file-content-wrapper w-full"
        @click="downloadFile()"
      >
        <div>
          <div
            class="flex flex-col items-center justify-center hit-input-file-content"
          >
            <img
              v-if="imgUrl"
              :src="imgUrl"
              alt="Image"
            >
            <hit-icon
              v-else
              class="w-full h-full"
              icon="file"
            />
          </div>
        </div>
        <div class="flex justify-center items-center">
          <p class="text-center pl-2">
            {{ designation }} ({{
              (Math.round(selectedFile.size * 100) / 100000000).toFixed(
                2
              ) + ' mo'
            }})
          </p>
        </div>
      </div>
    </div>
    <template #help>
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import {
  HitBaseInput,
  HitFormValidationMixin,
  HitInputMixin,
  HitBreakpointsMixin,
  HitIcon,
} from '@hit/components';
import {useI18n} from 'vue-i18n';
import {globals as $globals, globals} from 'hit-online-web-ui/src/globals';
import axios from 'axios';
import Compressor from 'compressorjs';
import {StorageService} from '../../../../api/data/StorageService';

export default {
  name: 'HitInputFile',
  components: {HitBaseInput, HitIcon},
  mixins: [HitFormValidationMixin, HitInputMixin, HitBreakpointsMixin],
  props: {
    /**
     * Value of the input
     */
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      upload_status: false,
      isDragging: false,
      selectedFile: null,
      active: true,
      designation: '',
      comment: '',
      userId: $globals.$appContext.user.id,
      fileId: null,
      file: null,
    };
  },
  created() {
    if (this.readonly || this.value) {
      this.loadFile();
    }
  },
  computed: {
    imgUrl() {
      if (this.value?.id && /\.(jpg|jpeg|png)$/i.test(this.value?.name ?? '')) {
        return `${StorageService.baseUrl}documents/for-report-api/${this.value.id}?jwt_token=${globals.$keycloak.token}`;
      }
      return undefined;
    },
  },
  methods: {
    async downloadFile() {
      try {
        const config = {
          headers: {
            Authorization: 'Bearer ' + globals.$keycloak.token,
            'Accept-Profile': globals.$keycloak.tokenParsed.schema_id,
          },
          responseType: 'arraybuffer',
        };
        const baseUrl = `${globals.$appContext.storageUrl}documents`;
        const url = `${baseUrl}/${this.value.id}/data`;
        await axios.get(url, config).then(function (response) {
          let blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          let filename = '';
          let contentDisposition = response.headers['content-disposition'];
          if (contentDisposition) {
            filename = (
              (contentDisposition.split('filename=')[1] || '').split(';')[0] ||
              ''
            )
              .replace(/'/g, '')
              .replace(/"/g, '');
          }
          link.download = filename;

          link.click();
        });
      } catch (error) {
        console.error(error);
      }
    },
    async loadFile() {
      try {
        const headers = {
          Authorization: `Bearer ${globals.$keycloak.token}`,
          'Content-Type': 'application/json',
        };
        const baseUrl = `${globals.$appContext.storageUrl}documents`;
        const url = `${baseUrl}/${this.value.id}`;
        let that = this;
        await axios.get(url, {headers}).then(function (response) {
          if (response) {
            if (response.data[0]) {
              that.selectedFile = response.data[0];
              that.selectedFile.type = '/data'; //TODO replace by mime-type after improving storage;
              that.selectedFile.size = that.value.size;
              that.designation = that.selectedFile.designation;
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getStaffId(userId) {
      const baseUrl = `${globals.$appContext.postgrestUrl}staff?`;
      const url = baseUrl + 'select=id&user_id=eq.' + userId;
      const headers = {
        Authorization: `Bearer ${globals.$keycloak.token}`,
        'Accept-Profile': globals.$keycloak.idTokenParsed.schema_id,
      };
      const author = await axios.get(url, {headers}); //TODO Replace by generic function or store?
      return author.data[0].id;
    },
    generateURL() {
      if (this.selectedFile.type.startsWith('image/')) {
        let fileSrc = URL.createObjectURL(this.selectedFile);
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc);
        }, 1000);
        return fileSrc;
      } else {
        return null;
      }
    },
    async remove() {
      try {
        const headers = {
          Authorization: `Bearer ${globals.$keycloak.token}`,
          'Content-Type': 'application/json',
        };
        const baseUrl = `${globals.$appContext.storageUrl}documents`;
        const url = `${baseUrl}/${this.value.id}`;
        await axios.delete(url, {headers});
        this.selectedFile = null;
      } catch (error) {
        console.error(error);
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.selectedFile = e.dataTransfer.files[0];
      this.isDragging = false;
      this.upload_status = true;
      this.uploadFile();
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.uploadFile();
    },
    async uploadFile() {
      if (this.selectedFile) {
        this.designation = this.selectedFile.name;
        this.upload_status = true;
        const fileData = {
          active: this.active,
          designation: this.designation,
          comment: this.comment,
          authorId: await this.getStaffId(this.userId),
        };
        try {
          const headers = {
            Authorization: `Bearer ${globals.$keycloak.token}`,
            'Content-Type': 'application/json',
          };
          const baseUrl = `${globals.$appContext.storageUrl}documents/`;
          const response = await axios.post(baseUrl, fileData, {headers});
          this.fileId = response.data.id;

          await this.uploadFileContent();

          let jsonFileData = {
            id: this.fileId,
            name: fileData.designation,
            comment: fileData.comment,
            authorId: fileData.authorId,
            size: this.selectedFile.size,
          };
          this.fireInputChange(jsonFileData);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async uploadFileContent() {
      if (this.fileId) {
        if (
          this.selectedFile.type.startsWith('image/') &&
          this.selectedFile.size > 500000
        ) {
          this.selectedFile = await this.compressFile();
        }
        try {
          const headers = {
            Authorization: `Bearer ${globals.$keycloak.token}`,
            'Content-Type': 'multipart/form-data',
          };
          const baseUrl = `${globals.$appContext.storageUrl}documents`;
          const formData = new FormData();
          formData.append('file', this.selectedFile);

          const url = `${baseUrl}/${this.fileId}/data`;

          await axios.post(url, formData, {headers});
          this.upload_status = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async compressFile() {
      return await new Promise((resolve, reject) => {
        new Compressor(this.selectedFile, {
          quality: 0.2,
          success: (compressedFile) => {
            if (compressedFile instanceof File) return resolve(compressedFile);
            else {
              const compressedFileFromBlob = new File(
                [compressedFile],
                this.selectedFile.name,
                {
                  type: compressedFile.type,
                }
              );
              return resolve(compressedFileFromBlob);
            }
          },
          error: reject,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.large-enough-for-form {
  .hit-input-file-image {
    @apply p-10 w-80;
  }

  .hit-input-file-content {
    @apply pb-5 pt-5;
  }

  .hit-input-file-content-wrapper {
    @apply pb-5 pr-5 pl-5;
  }
}

.hit-input-file-image {
  @apply w-48 p-2;
}

.hit-file-input.hit-form-row {
  .hit-form-row-label {
    margin-top: 0.55rem;
  }

  .hit-form-row-input {
    .inline-grid {
      @apply mt-0;
    }
  }
}
</style>
