import {Address, BaseModel, ProjectPart} from './index';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
import {translate} from '../../plugins/i18n/i18n';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';

export default class Purchase extends BaseModel {
  constructor({
    id,
    number,
    designation,
    description = undefined,
    memo = undefined,
    supplier = undefined,
    projectPart = undefined,
    documentDate = undefined,
    amount = undefined,
    expectedResponseDate = undefined,
    expectedOrderDate = undefined,
    expectedDeliveryDate = undefined,
    documentType = undefined,
  }) {
    super(id, number, designation);
    this.description = description;
    this.memo = memo;
    this.supplier = supplier ? new Address(supplier) : null;
    this.projectPart = projectPart ? new ProjectPart(projectPart) : null;
    this.documentDate = documentDate;
    this.amount = amount;
    this.expectedResponseDate = expectedResponseDate;
    this.expectedOrderDate = expectedOrderDate;
    this.expectedDeliveryDate = expectedDeliveryDate;
    this.documentType = documentType
      ? HitOnlineEnums.getEnumValue(
          'PURCHASE_DOCUMENT_TYPE',
          documentType,
          translate
        )
      : null;
  }

  static get printTemplateType() {
    return 'purchase';
  }
  get printTemplateType() {
    return Purchase.printTemplateType;
  }

  static get config() {
    return {
      addable: true,
      duplicatable: false,
      importable: false,
      browsable: true,
      deletable: true,
    };
  }

  get config() {
    return Purchase.config;
  }

  static get fkName() {
    return 'purchase_id';
  }
  static get apiRoute() {
    return 'purchase';
  }
  static get detailPageRoute() {
    return 'purchaseDetail';
  }
  static get listPageRoute() {
    return 'purchaseList';
  }
  get fkName() {
    return Purchase.fkName;
  }
  get apiRoute() {
    return Purchase.apiRoute;
  }
  get detailPageRoute() {
    return Purchase.detailPageRoute;
  }
  get listPageRoute() {
    return Purchase.listPageRoute;
  }

  static get listDisplayAttrs() {
    return this.getAttributes(
      [
        'number',
        'designation',
        'supplier',
        'projectPart',
        'amount',
        'documentType',
        'documentDate',
        'expectedDeliveryDate',
        'tags',
      ],
      ['expectedResponseDate', 'expectedOrderDate']
    );
  }
  static get entityListDisplayAttrs() {
    return ['designation', 'documentNumber', 'documentDate', 'journal', 'tags'];
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'supplier',
      'projectPart',
      'amount',
      'documentDate',
      'tags',
    ]);
  }

  static get joinAttrsKey() {
    return [
      'number',
      'designation',
      'supplier',
      'projectPart',
      'amount',
      'documentDate',
      'tags',
    ];
  }

  static get createInitColumns() {
    return {
      designation: '',
      document_date: new Date(),
      expected_response_date: new Date(),
      expected_order_date: new Date(),
      expected_delivery_date: new Date(),
      document_type: 'PURCHASE_ORDER',
    };
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.75fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          maxWidth: '1fr',
        },
      }),
      description: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.description',
          maxWidth: '1fr',
        },
      }),
      supplier: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_ADDRESS, {
        column: 'address_id',
        joinPath: ['address!fk_purchase_address_id_address'],
        tableProperties: {
          header: 'hit-app.common.supplier',
        },
      }),
      projectPart: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_PROJECT_PART, {
        column: 'project_part',
        joinPath: ['project_part!fk_purchase_project_part_id_project_part'],
        tableProperties: {
          header: 'hit-base.common.project-part',
        },
      }),
      amount: new HitContainerAttribute(ATTRIBUTE_TYPES.AGGREGATE_FUNCTION, {
        column: 'purchase_item(purchase_amount.sum())',
        transform: (total) =>
          Array.isArray(total) && total[0] && total[0].sum ? total[0].sum : 0,
        tableProperties: {
          header: 'hit-app.common.amount',
          maxWidth: '0.75fr',
        },
        forcedType: 'decimal',
        decimalPrecision: 2,
      }),
      documentType: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'PURCHASE_DOCUMENT_TYPE',
        column: 'document_type',
        tableProperties: {
          header: 'hit-app.common.document-type',
          maxWidth: '0.5fr',
        },
      }),
      documentDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'document_date',
        tableProperties: {
          header: 'hit-app.common.document-date',
          maxWidth: '0.5fr',
        },
      }),
      expectedResponseDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'expected_response_date',
        tableProperties: {
          header: 'hit-app.purchase.expected-response-date',
          maxWidth: '0.5fr',
        },
      }),
      expectedOrderDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'expected_order_date',
        tableProperties: {
          header: 'hit-app.purchase.expected-order-date',
          maxWidth: '0.5fr',
        },
      }),
      expectedDeliveryDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'expected_delivery_date',
        tableProperties: {
          header: 'hit-app.purchase.expected-delivery-date',
          maxWidth: '0.5fr',
        },
      }),
      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
          width: '1fr',
        },
      }),
    };
  }
}
