import {BaseModel, Cost, Resource, Unit} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';

export default class CostItem extends BaseModel {
  constructor({
    id,
    number,
    designation,
    active = true,
    cost = undefined,
    resource = undefined,
    unit = undefined,
    quantity = undefined,
    quantityExpression = undefined,
    price = undefined,
    amount = undefined,
    comment = undefined,
  }) {
    super(id, number, designation);
    this.active = active;
    this.cost = cost ? new Cost(cost) : null;
    this.resource = resource ? new Resource(resource) : null;
    this.unit = unit ? new Unit(unit) : null;
    this.quantity = quantity;
    this.quantityExpression = quantityExpression;
    this.price = price;
    this.amount = amount;
    this.comment = comment;
  }

  static get fkName() {
    return undefined;
  }
  static get apiRoute() {
    return 'cost_data';
  }
  static get detailPageRoute() {
    return 'costsView';
  }
  static get listPageRoute() {
    return 'costsView';
  }
  get fkName() {
    return CostItem.fkName;
  }
  get apiRoute() {
    return CostItem.apiRoute;
  }
  get listPageRoute() {
    return CostItem.listPageRoute;
  }
  get detailPageRoute() {
    return CostItem.detailPageRoute;
  }

  static get joinAttrsKey() {
    return ['number', 'designation', 'cost', 'quantity', 'comment'];
  }
  static get listDisplayAttrs() {
    return this.getAttributes(
      [
        'number',
        'designation',
        'unit',
        'quantity',
        'price',
        'amount',
        'comment',
      ],
      ['resource']
    );
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-app.common.designation',
          maxWidth: '2fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
      cost: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_COST, {
        column: 'cost_id',
        joinPath: ['cost!fk_cost_data_cost_id_cost'],
        tableProperties: {
          header: 'hit-base.common.cost',
        },
      }),
      resource: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        column: 'resource_id',
        joinPath: ['resource!fk_cost_data_resource_id_resource'],
        tableProperties: {
          header: 'hit-base.common.resource',
        },
      }),
      unit: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_UNIT, {
        column: 'unit_id',
        joinPath: ['unit!fk_cost_data_unit_id_unit'],
        tableProperties: {
          header: 'hit-base.common.unit',
          maxWidth: '0.5fr',
        },
      }),
      quantity: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 3,
        decimalDefaultValue: 0,
        tableProperties: {
          header: 'hit-app.common.quantity',
        },
      }),
      quantityExpression: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'quantity_expression',
        tableProperties: {
          header: 'hit-app.transaction.expression',
        },
      }),
      price: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        default: 0.0,
        tableProperties: {
          header: 'hit-app.common.price',
        },
      }),
      amount: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        default: 0.0,
        readOnly: true,
        tableProperties: {
          readOnly: true,
          header: 'hit-app.common.amount',
        },
      }),
      comment: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.comment',
          maxWidth: '1fr',
        },
      }),
    };
  }
}
