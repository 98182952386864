<template>
  <div class="hit-form-row">
    <div
      v-if="label || displayLabelColumn"
      :class="{
        'inline-row': inlineInput,
        'help-visible': helpVisible,
        'hit-form-row-label': rowLabel,
        'center-label-vertically': !useCustomHeight,
        'position-label-vertically-via-margin': useCustomHeight,
        'align-left': labelAlignmentSide === 'left',
        'align-right': labelAlignmentSide === 'right',
        'align-center': labelAlignmentSide === 'center',
      }"
    >
      <div
        v-if="helpVisible"
        class="order-2 hit-form-help mt-0.5"
      >
        <slot name="help" />
      </div>
      <label
        class="hit-form-label"
        :class="{
          'text-panel': !inlineInput || inlineLabelColorVisible,
          'text-panel-light': inlineInput && !inlineLabelColorVisible,
          'order-1': helpVisible,
        }"
      >
        {{ label }}
        <span
          v-if="pristine && required && invalid"
          class="text-danger"
        >*</span>
      </label>
    </div>
    <div
      class="hit-form-row-input min-w-16"
      :class="{
        'inline-row': inlineInput,
        'mt-2': addTopMargin,
      }"
    >
      <div
        :class="{
          'w-full': !fullWidth,
          'height-8': !useCustomHeight,
          'min-height': setMinHeight,
        }"
      >
        <slot />
        <span
          v-if="pristine && required && invalid && !label"
          class="text-danger ml-1"
        >*</span>
      </div>
    </div>
    <div
      v-if="errorsVisible"
      class="hit-form-row-errors"
    >
      <slot name="customErrorMessage">
        <div
          v-for="error in validationErrors"
          :key="error.translationKey"
          class="text-danger-icon"
        >
          {{ t(error.translationKey, error.params) }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import {getValidationErrors} from '@hit/components';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitBaseInput',
  components: {},
  mixins: [HitFormValidationMixin, HitInputMixin],
  props: {
    fullWidth: {
      type: Boolean,
      default: true,
    },
    rowLabel: {
      type: Boolean,
      default: true,
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
    labelAlignmentSide: {
      type: String,
      required: false,
      default: 'right',
      validator: (value) => {
        return ['right', 'left', 'center'].indexOf(value) !== -1;
      },
    },
    addTopMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
    setMinHeight: {
      type: Boolean,
      required: false,
      default: true,
    },
    onlyShowErrorBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  computed: {
    validationErrors() {
      return getValidationErrors(this.validationState);
    },
    errorsVisible() {
      if (this.onlyShowErrorBorder) return false;
      return (
        (this.showErrors && this.validationErrors.length > 0) ||
        this.$slots.customErrorMessage
      );
    },
    helpVisible() {
      return !!this.$slots['help'];
    },
    inputGridStyle() {
      return {
        'grid-template-rows': this.useCustomHeight ? '1fr' : '2rem',
        'grid-template-columns': 'minmax(0, 1fr)',
      };
    },
    cssProps() {
      return {
        '--first-column-width-inline-row': this.inlineLabelWidth,
      };
    },
    console() {
      return console;
    },
  },
  methods: {
    triggerValidations() {
      try {
        this.validationState.$touch();
      } catch {
        /** We simply try to trigger the validation **/
      }
    },
  },
  mounted() {
    if (this.showErrors) {
      this.triggerValidations();
    }
  },
  watch: {
    validationState: {
      handler(newValue) {
        if (
          newValue &&
          newValue.$invalid &&
          newValue.$model != null &&
          !Array.isArray(newValue.$model)
        ) {
          newValue.$touch();
        }
      },
      immediate: true,
      // Need to watch deep, otherwise the watch won't compare the object properties
      deep: true,
    },
    showErrors: {
      handler(newValue) {
        if (newValue) {
          this.triggerValidations();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
/* Unset any previous grid settings for the label and the input */
.hit-input-group {
  .hit-form-row {
    .hit-form-row-label {
      grid-column: unset;
    }

    .hit-form-row-input {
      grid-column: unset;
    }
  }
}

.hit-form-row-label {
  font-weight: 500;
}

.large-enough-for-form {
  .hit-form-row {
    .hit-form-help {
      @apply order-1;
    }

    .hit-form-label {
      white-space: nowrap;
      @apply order-2 text-right;
    }

    .hit-form-row-label {
      grid-column: 1;
      font-weight: 500;

      &.center-label-vertically {
        display: flex;
        align-items: center;
        &.align-left {
          justify-content: start;
        }
        &.align-right {
          justify-content: end;
        }
        &.align-center {
          justify-content: center;
        }
      }

      &.position-label-vertically-via-margin {
        @apply justify-end mt-1.5;
      }

      &.inline-row {
        grid-column: unset;
      }
    }

    .hit-form-row-input {
      grid-column: 2;
      width: 100%;

      .height-8 {
        height: 2rem;
      }

      &.inline-row {
        grid-column: unset;
      }
    }

    .hit-form-row-errors {
      grid-column: 2;
      margin-left: 5px;
      @apply text-sm text-danger leading-none;
    }
  }
}

.hit-form-row {
  @apply contents;

  .hit-form-row-label {
    @apply inline-grid gap-2;
    grid-template-columns: minmax(0, max-content);

    &.help-visible {
      grid-template-columns: minmax(0px, max-content) minmax(0px, max-content);
    }
  }

  .hit-form-row-input > div:first-child {
    @apply grid;
    grid-template-columns: max-content min-content;

    &.h-8 {
      grid-template-rows: 2rem;
    }

    &.w-full {
      grid-template-columns: 1fr min-content;
    }
  }

  /* This rule is important to prevent CodeMirror from overflowing when code is too long inside a grid
     Source: https://discuss.codemirror.net/t/codemirror-inside-css-grid/1208/2 */
  //.hit-form-row-input {
  //  @apply min-w-0;
  //}
}

.min-height {
  min-height: 2rem;
}
</style>
