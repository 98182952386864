import {translate} from '@hit/base/src/plugins/i18n/i18n';
import {HitDateUtils} from '@hit/components/src/utils/date/HitDateUtils';

export class PrintConfigService {
  static REUSABLE_CONFIGS = {
    storeFile: {
      id: 'store-file',
      type: 'boolean', // The type needs to match the used type in hit-input-generic component
      label: 'hit-app.printer-configuration.store-file',
      default: false,
    },
    displayAmountInWords: {
      id: 'displayAmountInWords',
      type: 'boolean',
      label: 'hit-app.custom-print-config.transaction-display-amount-words',
      default: false,
    },
    fromDateTime: {
      id: 'fromDate',
      type: 'datetime-local', // The type needs to match the used type in hit-input-generic component
      label: 'hit-base.common.from',
      default: HitDateUtils.setToLocalMidnight(new Date()),
    },
    toDateTime: {
      id: 'toDate',
      type: 'datetime-local',
      label: 'hit-base.common.to',
      default: HitDateUtils.setToLocalEndOfDay(new Date()),
    },
    fromDate: {
      id: 'fromDate',
      type: 'date',
      label: 'hit-base.common.from',
      default: HitDateUtils.firstDayLastMonth(),
    },
    toDate: {
      id: 'toDate',
      type: 'date',
      label: 'hit-base.common.to',
      default: HitDateUtils.lastDayLastMonth(),
    },
  };

  static TEMPLATE_CONFIGS = {
    actionSummary: [
      PrintConfigService.REUSABLE_CONFIGS.fromDateTime,
      PrintConfigService.REUSABLE_CONFIGS.toDateTime,
    ],
    transactionDefault: [
      PrintConfigService.REUSABLE_CONFIGS.displayAmountInWords,
    ],
    transactionQuotation: [
      PrintConfigService.REUSABLE_CONFIGS.displayAmountInWords,
    ],
    projectPartQuotation: [
      PrintConfigService.REUSABLE_CONFIGS.displayAmountInWords,
    ],
    staffHoursDetails: [
      PrintConfigService.REUSABLE_CONFIGS.fromDate,
      PrintConfigService.REUSABLE_CONFIGS.toDate,
    ],
  };

  /**
   * Returns a list of objects with one object per config that can be set for this template
   */
  static getTemplateConfigs(templateType) {
    return this.TEMPLATE_CONFIGS?.[templateType] ?? [];
  }
}
