<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-form')"
    route="form"
    :table-attributes="tableAttributes"
    auto-complete-id="form"
    :data-list-config="dataListConfig"
    :default-sort="defaultSort"
  >
    <template #item.status.display="{formData}">
      <span>
        {{ t(`${new Form({status: formData.status}).getStatusTextKey()}`) }}
      </span>
    </template>
  </hit-entity-browser>
</template>

<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
import {Form} from '@hit/base';

export default {
  name: 'HitFormBrowser',
  components: {
    HitEntityBrowser,
  },
  data() {
    return {
      tableAttributes: Form.listDisplayAttrs,
      dataListConfig: {
        zone1CustomFunction: (item) =>
          item[
            `formDefinitionDesignation${this.config.companyLanguagesPascalCase}`
          ] + (item.designation ? ` (${item.designation})` : ''),
        zone2Field: 'linkedEntity',
        zone3CustomFunction: (item) =>
          `${item?.author?.entityDesignation} - ${this.$d(
            new Date(item.formDate),
            'short'
          )}`,
      },
      defaultSort: {property: 'formDate', order: 'desc'},
    };
  },
  computed: {
    Form() {
      return Form;
    },
  },
  inject: ['tHitField', 'toSnakeCase'],
  setup() {
    const {t} = useI18n();
    return {t};
  },
};
</script>
