// @ts-nocheck
import { useConfigurationStore } from '@hit/base';
import { useI18n } from 'vue-i18n';
import { getDataTypeClass } from './HitContainerHelpers';
export const ATTRIBUTE_TYPES = {
    STRING: 0,
    BOOLEAN: 1,
    ARRAY: 2,
    DATE: 3,
    DECIMAL: 4,
    FOREIGN_ID: 6,
    DATETIME: 7,
    ML_WITH_FALLBACK: 8,
    COLOUR: 9,
    INT: 10,
    NUMBER: 11,
    NOT_FETCHED: 12,
    ENUMERATION: 14,
    TAGS: 15,
    AGGREGATE_FUNCTION: 16,
    DATETIME_SECONDS: 17,
    RICH_TEXT: 18,
    JSON_OBJECT: 19,
    LANGUAGE: 90,
    // ATTRIBUTE TYPES THAT ARE USED FOR JOINED ENTITIES TO IMPROVE DATA HANDLING
    FK_ADDRESS: 101,
    FK_STAFF: 102,
    FK_EQUIPMENT: 103,
    FK_RESOURCE: 104,
    FK_PROJECT: 105,
    FK_PROJECT_PART: 106,
    FK_TRANSACTION: 107,
    FK_ACTION: 108,
    FK_FORM: 109,
    FK_JOURNAL: 110,
    FK_TAX_CODE: 111,
    FK_DUE_DATE: 112,
    FK_COUNTRY: 113,
    FK_UNIT: 114,
    FK_RESOURCE_CATEGORY: 115,
    FK_PERIOD: 116,
    FK_TRANSACTION_ITEM: 117,
    FK_COST: 118,
    FK_FORM_DEFINITION: 119,
    FK_QUALIFICATION: 120,
    FK_PURCHASE: 121,
    FK_PURCHASE_ITEM: 122,
    FK_TAGS: 123,
    FK_COST_ITEM: 124,
    FK_DAY_TYPE: 126,
    FK_ACTIVITY: 127,
};
export class HitContainerAttribute {
    dataType;
    dataObjectClass = null;
    column = null;
    creationValue = null;
    validations = {};
    validationGroup = null;
    transform = null;
    decimalPrecision;
    decimalDefaultValue;
    readOnly; // used to declare readonly values in the db, will prevent writing this value into db
    formLocation;
    tableProperties = {};
    forcedType;
    formItemName; // is used instead of the column attribute to store the data in the form object
    fkToUpdate;
    enumType;
    joinPath;
    onlyFetchDesignation;
    customFilters;
    constructor(dataType, { column = null, creationValue = null, validations = {}, validationGroup = null, transform = null, decimalPrecision = 2, decimalDefaultValue = 0, readOnly = false, formLocation = null, tableProperties = {}, forcedType = null, formItemName = null, fkToUpdate = null, enumType = null, joinPath = [], onlyFetchDesignation = false, customFilters = null, } = {}) {
        this.dataType = dataType;
        this.creationValue = creationValue;
        this.validations = validations || {};
        this.validationGroup = validationGroup || null;
        this.transform = transform;
        this.decimalPrecision = decimalPrecision;
        this.decimalDefaultValue = decimalDefaultValue;
        this.readOnly = readOnly;
        this.formLocation = formLocation;
        this.tableProperties = tableProperties;
        this.forcedType = forcedType;
        this.formItemName = formItemName;
        this.fkToUpdate = fkToUpdate;
        this.enumType = enumType;
        this.joinPath = joinPath;
        this.customFilters = customFilters;
        this.onlyFetchDesignation = onlyFetchDesignation;
        if (dataType === ATTRIBUTE_TYPES.TAGS) {
            this.column = {
                table: column?.table ?? 'tag',
                column: column?.column ?? 'designation',
                selectColumns: 'id, designation, colour',
            };
        }
        else {
            this.column = column;
        }
        if (this.dataType > 100) {
            this.dataObjectClass = getDataTypeClass(this.dataType);
        }
    }
    getInitialValue() {
        const configStore = useConfigurationStore();
        if (this.dataType === ATTRIBUTE_TYPES.STRING) {
            return this.creationValue ? this.creationValue.toString() : undefined;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.BOOLEAN) {
            return this.creationValue ? Boolean(this.creationValue) : false;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.DECIMAL) {
            if (typeof this.decimalDefaultValue === 'string') {
                return parseFloat(this.decimalDefaultValue).toFixed(this.decimalPrecision);
            }
            else if (typeof this.decimalDefaultValue === 'number') {
                return this.decimalDefaultValue.toFixed(this.decimalPrecision);
            }
        }
        else if (this.dataType === ATTRIBUTE_TYPES.ARRAY) {
            return this.creationValue ? this.creationValue : [];
        }
        else if (this.dataType === ATTRIBUTE_TYPES.DATE ||
            this.dataType === ATTRIBUTE_TYPES.DATETIME ||
            this.dataType === ATTRIBUTE_TYPES.DATETIME_SECONDS) {
            let currentDate = new Date();
            let year = currentDate.getFullYear();
            let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month starts from 0
            let day = String(currentDate.getDate()).padStart(2, '0');
            const today = `${year}-${month}-${day}`;
            return this.creationValue ? this.creationValue : today;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.FOREIGN_ID) {
            return this.creationValue ? this.creationValue : null;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.COLOUR) {
            return this.creationValue ? this.creationValue : '2358a8';
        }
        else if (this.dataType === ATTRIBUTE_TYPES.INT) {
            return this.creationValue ? this.creationValue : null;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.NUMBER) {
            return this.creationValue ? this.creationValue : null;
        }
        else if (this.creationValue) {
            return this.creationValue;
        }
    }
}
export function generateMultiLanguageAttributes(prefix, type, options = {}) {
    const generatedAttributes = {};
    const configStore = useConfigurationStore();
    const t = useI18n().t;
    const locales = configStore.companyLanguagesSnakeCase;
    let firstLocale = true;
    locales.forEach((locale) => {
        locale = locale.replace(/-/g, '_').toLowerCase();
        const localizedConfig = replaceLocale(options, locale);
        const adaptedTableProperties = { ...options.tableProperties };
        adaptedTableProperties.header = `${adaptedTableProperties.header} (${t(`hit-base.common.${locale}`)})`;
        generatedAttributes[`${prefix}_${locale}`] = new HitContainerAttribute(type, {
            column: localizedConfig.column,
            validations: options.validations,
            transform: localizedConfig.transform,
            decimalPrecision: localizedConfig.decimalPrecision,
            decimalDefaultValue: localizedConfig.decimalDefaultValue,
            tableProperties: locales.length > 1 ? adaptedTableProperties : options.tableProperties,
        });
    });
    return generatedAttributes;
}
/**
 * Replaces all LOCALE placeholder in the given object by the passed localeVariable
 * @param object - object where we want to replace the placeholders
 * @param localeVariable - used locale
 */
function replaceLocale(object, localeVariable) {
    const replacedObject = {};
    for (const [key, value] of Object.entries(object)) {
        if (typeof value === 'object' && value !== null) {
            replacedObject[key] = replaceLocale(value, localeVariable);
        }
        else if (typeof value === 'string') {
            replacedObject[key] = value.replace(/LOCALE/g, localeVariable);
        }
        else if (typeof value === 'function') {
            const functionSource = value.toString();
            const replacedFunctionSource = functionSource.replace(/LOCALE/g, localeVariable);
            replacedObject[key] = eval(`(${replacedFunctionSource})`);
        }
        else {
            replacedObject[key] = value;
        }
    }
    return replacedObject;
}
