import breakpoints from '../../utils/breakpoints/breakpoints';

export default {
  computed: {
    responsiveBreakpointXs() {
      return breakpoints.responsiveBreakpointXs;
    },
    responsiveBreakpointSm() {
      return breakpoints.responsiveBreakpointSm;
    },
    responsiveBreakpointMd() {
      return breakpoints.responsiveBreakpointMd;
    },
    responsiveBreakpointLg() {
      return breakpoints.responsiveBreakpointLg;
    },
    responsiveBreakpointXl() {
      return breakpoints.responsiveBreakpointXl;
    },
    responsiveBreakpointXxl() {
      return breakpoints.responsiveBreakpointXxl;
    },
    responsiveBreakpointXxxl() {
      return breakpoints.responsiveBreakpointXxxl;
    },
    responsiveBreakpointHeightMd() {
      return breakpoints.responsiveBreakpointHeightMd;
    },
    responsiveBreakpointHeightXl() {
      return breakpoints.responsiveBreakpointHeightXl;
    },
  },
};
