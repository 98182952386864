import {HitUtils} from '../hit/HitUtils';
import {$d} from '../../plugins/i18n/i18n';

export class HitDateUtils extends HitUtils {
  /**
   * Returns the 1st January of the same year then the passed day
   */
  static firstDayOfYear(dateObject) {
    return this.setToUtcMidnight(new Date(dateObject.getFullYear(), 0, 1));
  }

  /**
   * Formats the date object to use it as postgrest query parameter
   */
  static toPostgrestParam(dateObject, onlyDate = false) {
    return onlyDate ? this.toLocalDate(dateObject) : dateObject.toISOString();
  }

  /**
   * Returns the date converted in the local timezone
   * 05/08/2024 22:00:00 +00:00 becomes 06/08/2024 00:00 +02:00
   * Returns then 2024-08-06 always in this format
   */
  static toLocalDate(dateObject) {
    return dateObject.toLocaleDateString('en-CA');
  }

  /**
   * Returns the time from the date object in the local timezone
   * 05/08/2024 22:00:00 +00:00 becomes 06/08/2024 00:00 +02:00
   * Returns then 00:00:00 always in this format
   */
  static toLocalTime(dateObject) {
    return dateObject.toLocaleTimeString();
  }

  /**
   * Formats the dateObject to only display year, month and day in the locale format
   */
  static toShortDate(dateObject) {
    if (!dateObject) return null;
    return $d(dateObject, 'short');
  }

  /**
   * Formats the dateObject to display year, month, day and then hours and minutes
   * By setting the show seconds flag, we also display the second in the time output
   */
  static toLongDate(dateObject, showSeconds = false) {
    return showSeconds ? $d(dateObject, 'longSeconds') : $d(dateObject, 'long');
  }

  /**
   * Displays only the time part of the date without displaying the date
   */
  static toTime(dateObject, showSeconds = false) {
    return showSeconds
      ? this.toLongDate(dateObject, true).slice(-8)
      : this.toLongDate(dateObject).slice(-5);
  }

  /**
  * Checks if its midnight
  * */

  static isMidnight(dateObject, considerSeconds = false) {
    const time = this.toTime(dateObject, considerSeconds);
    return time === (considerSeconds ? '00:00:00' : '00:00');
  }

  /**
   * Returns the date object of the Monday of the same week
   */
  static getMondayOfWeek(dateObject) {
    const monday = new Date(dateObject);
    const day = dateObject.getDay();
    const distanceToMonday = (day + 6) % 7;
    monday.setDate(dateObject.getDate() - distanceToMonday);
    return monday;
  }

  /**
   * For some use-cases, we need to be at UTC midnight to avoid bugs when displaying the day in an input of type date
   */
  static setToUtcMidnight(dateObject) {
    const tz = dateObject.getTimezoneOffset();
    const midnight = new Date(dateObject);
    midnight.setHours(0, 0, 0, 0);
    midnight.setMinutes(-tz);
    return midnight;
  }

  /**
   * Sets the time to midnight in local time
   * For our timezone, midnight of our time is still 22h00 for the previous day
   */
  static setToLocalMidnight(dateObject) {
    const midnight = new Date(dateObject);
    midnight.setHours(0, 0, 0, 0);
    return midnight;
  }

  /**
   * Sets the time to 23:59:59 in the local time of the user
   */
  static setToLocalEndOfDay(dateObject) {
    const endOfDay = new Date(dateObject);
    endOfDay.setHours(23, 59, 59, 0);
    return endOfDay;
  }

  /**
   * Converts the weekCode like 202431 (week 31 of 2024) to the first day of the week
   */
  static weekToDay(weekCode) {
    const year = parseInt(weekCode.toString().slice(0, 4), 10);
    const week = parseInt(weekCode.toString().slice(4), 10) - 1;
    const jan1 = new Date(year, 0, 1);
    const jan1Day = jan1.getDay();
    const firstMondayOffset = jan1Day === 0 ? 1 : 8 - jan1Day;
    const daysToAdd = (week - 1) * 7 + firstMondayOffset - 1;
    return this.$d(new Date(jan1.getTime() + daysToAdd * 24 * 60 * 60 * 1000));
  }

  static toEndOfDay(dateObject) {
    const tz = dateObject.getTimezoneOffset();
    const midnight = new Date(dateObject);
    midnight.setHours(23, 59, 59, 0);
    midnight.setMinutes(-tz);
    return midnight;
  }

  /**
   * Converts a string like 01:20:25 into the number of seconds -> 4825 seconds
   */
  static hhmmssToSeconds(timeString) {
    const [h, m, s] = timeString.split(':');
    return parseInt(h) * 3600 + parseInt(m) * 60 + parseInt(s);
  }

  /**
   * Creates a day object for day + x
   */
  static nextDay(dayObject, numberOfDays = 1) {
    let nextDay = new Date(dayObject);
    nextDay.setDate(dayObject.getDate() + numberOfDays);
    return nextDay;
  }

  /**
   * When we have midnight local time 00:00:00 +02:00, and we pass this object
   * to an input, it displays the previous day. For this, we need to set it at
   * midnight UTC to display the correct date
   */
  static formatForDayInput(dayObject) {
    return this.setToUtcMidnight(dayObject);
  }

  /**
   * Creates a day object for the first day of the previous month
   */
  static firstDayLastMonth() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setDate(1);
    return this.setToUtcMidnight(date);
  }

  /**
   * Creates a day object for the last day of the previous month
   */
  static lastDayLastMonth() {
    const date = new Date();
    date.setDate(0);
    return this.setToUtcMidnight(date);
  }
}
