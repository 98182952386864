<template>
  <hit-select
    v-if="!readOnly"
    :label-fields="['designation']"
    :value="selectedTags"
    :options="mergedTags"
    :options-to-exclude="deactivatedTags"
    multiple
    @remove="(tagId) => $emit('remove', tagId)"
  />
  <div
    v-else
    class="flex flex-row gap-1 flex-wrap"
  >
    <hit-entity-list :entities-to-display="tagObjectList" />
  </div>
</template>

<script>
import HitEntityList from './HitEntityList.vue';
import HitSelect from '../form/input/HitSelect.vue';

export default {
  name: 'TagList',
  components: {HitSelect, HitEntityList},
  props: {
    /**
     * We move the tag-list to the components package. To avoid import errors,
     * we need to pass the dataService as prop to the component
     */
    dataService: {
      type: Object,
      required: true,
    },

    /**
     * The user passes an array of objects. No mapping needed because entire data in object
     */
    tagObjectList: {
      type: Array,
      required: false,
      default: () => [],
    },

    /**
     * Flag to make the tag list read only. Clear icons will be removed from selected tags
     */
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Can be used to pass the available tags instead of making one request per line to load the active tags
     * Like this, they are loaded once with the container data and can then be used for the display of the tags
     */
    container: {
      type: Object,
      required: false,
    },

    /**
     * Pass an array of entities with the different entities the tags are loaded
     * For example ['address', 'staff'] loads all tags where address_visible or
     * staff_visible is set to true
     */
    tagEntitiesToLoad: {
      type: Array,
      required: false,
      default: null,
    },

    /**
     * The read only implementation existed already but in some cases, we need to add a clear icon behind the tag
     */
    removableReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tags: [], // Available tags for the mentioned entity type
    };
  },

  computed: {
    /**
     * We only display the tags that belong to the entities we want to load
     * If no entity types are passed, we display all the tags
     * project_part needs to fall back to project because they use the same column
     */
    tagLoadEntityFilter() {
      if (this.tagEntitiesToLoad !== null) {
        let orStatement = '';
        this.tagEntitiesToLoad.forEach((entity) => {
          const entityKey = entity === 'project_part' ? 'project' : entity;
          orStatement += `${entityKey}_visible.is.true,`;
        });
        return `(${orStatement.slice(0, -1)})`;
      }
      return null;
    },

    /**
     * We need to merge the tag options and the tag from the list for the case
     * when a tag has been deactivated for this entity type
     */
    mergedTags() {
      const combinedTags = [...this.tags, ...this.tagObjectList].filter(
        (t) => t?.designation
      );
      return combinedTags.filter(
        (obj, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.id === obj.id &&
              t.colour === obj.colour &&
              t.designation === obj.designation
          )
      );
    },

    /**
     * The tags that have been added to the options to display existing usages
     * needs to be removed from the options by adding them to options-to-exclude
     */
    deactivatedTags() {
      return this.tagObjectList.filter(
        (obj) =>
          !this.tags.some(
            (tag) =>
              tag.id === obj.id &&
              tag.colour === obj.colour &&
              tag.designation === obj.designation
          )
      );
    },

    /**
     * Array of UUIDs with the selected tags
     */
    selectedTags() {
      return this.tagObjectList.map((tag) => tag.id);
    },
  },

  methods: {
    /**
     * Fetches the possible tags from the database. Filter depends on actual entity
     */
    loadTags() {
      let filter = {active: 'is.true'};
      if (this.tagLoadEntityFilter) {
        filter['or'] = this.tagLoadEntityFilter;
      }
      this.dataService
        .read('tag', {
          attributes: 'id,designation,colour',
          filters: filter,
        })
        .then((response) => (this.tags = response.data));
    },
  },

  /**
   * When the component is loaded, we need to fetch the options to display
   */
  mounted() {
    if (!this.container && !this.readOnly) {
      this.loadTags();
    }
  },

  emits: ['remove'],
};
</script>
