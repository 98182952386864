<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-resource')"
    route="resource"
    auto-complete-id="resource"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'number', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :data-list-config="listConfig"
    @item-selected="selectItem"
  >
    <template #item.resourceType.display="{formData}">
      <!-- will check if an icon can be created for titles instead.-->
      <div class="grid items-center">
        <template v-if="formData?.resourceType?.id === 'TITLE'">
          <div
            class="w-4 h-4 flex items-center justify-center rounded text-black text-sm font-normal"
          >
            {{ t('hit-app.resource.title-short') }}{{ formData.titleLevel + 1 }}
          </div>
        </template>
        <template v-else>
          <hit-icon
            :icon="Resource.getIcon(formData.resourceType)"
            in-table
          />
        </template>
      </div>
    </template>
  </hit-entity-browser>
</template>

<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {useConfigurationStore, useUserProfileStore} from '../../../store';
import {Resource} from '../../../models';
import {HitIcon} from '@hit/components/src';

export default {
  name: 'HitResourceBrowser',
  components: {
    HitEntityBrowser,
    HitIcon,
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    instantFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const c = useConfigurationStore();
    const user = useUserProfileStore();
    const {t, n} = useI18n();
    return {t, n, c, user};
  },
  computed: {
    Resource() {
      return Resource;
    },
    tableAttributes() {
      return this.user.ownsModule('resource-base')
        ? Resource.browserDisplayAttrs
        : Resource.confidentialBrowserDisplayAttrs;
    },

    listConfig() {
      if (this.user.ownsModule('resource-base')) {
        return {
          zone1Field: 'number',
          zone2Field: `designation${this.c.mainLanguagePascalCase}`,
          zone3CustomFunction: (item) =>
            `${this.t('hit-app.common.sales-price')}: ${this.n(
              parseFloat(item?.salesPrice ?? 0),
              'price'
            )} / ${item?.unit?.unit ?? ''}`,
        };
      } else {
        return {
          zone1Field: 'number',
          zone2Field: `designation${this.c.mainLanguagePascalCase}`,
        };
      }
    },
  },

  methods: {
    selectItem(data) {
      const res = new Resource(data);
      this.$emit('itemSelected', res);
    },
  },
  emits: ['itemSelected'],
};
</script>
