import {reactive} from 'vue';

const screens = {
  xs: 440,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 2575,
  xxxl: 3340,
  heightMd: 520,
  heightXl: 900,
};

const responsiveBreakpointXs = (val) => {
  return val >= screens.xs;
};
const responsiveBreakpointSm = (val) => {
  return val >= screens.sm;
};
const responsiveBreakpointMd = (val) => {
  return val >= screens.md;
};
const responsiveBreakpointLg = (val) => {
  return val >= screens.lg;
};
const responsiveBreakpointXl = (val) => {
  return val >= screens.xl;
};
const responsiveBreakpointXxl = (val) => {
  return val >= screens.xxl;
};
const responsiveBreakpointXxxl = (val) => {
  return val >= screens.xxxl;
};
const responsiveBreakpointHeightMd = (val) => {
  return val >= screens.heightMd;
};
const responsiveBreakpointHeightXl = (val) => {
  return val >= screens.heightXl;
};

let timeout = null;

const breakpoints = reactive({
  responsiveBreakpointXs: responsiveBreakpointXs(window.innerWidth),
  responsiveBreakpointSm: responsiveBreakpointSm(window.innerWidth),
  responsiveBreakpointMd: responsiveBreakpointMd(window.innerWidth),
  responsiveBreakpointLg: responsiveBreakpointLg(window.innerWidth),
  responsiveBreakpointXl: responsiveBreakpointXl(window.innerWidth),
  responsiveBreakpointXxl: responsiveBreakpointXxl(window.innerWidth),
  responsiveBreakpointXxxl: responsiveBreakpointXxxl(window.innerWidth),
  responsiveBreakpointHeightMd: responsiveBreakpointHeightMd(
    window.visualViewport ? window.visualViewport.height : window.innerHeight
  ),
  responsiveBreakpointHeightXl: responsiveBreakpointHeightXl(
    window.visualViewport ? window.visualViewport.height : window.innerHeight
  ),
});

window.addEventListener('resize', function () {
  clearTimeout(timeout);
  // gets only executed after event hasn't been fired for 50 ms
  timeout = setTimeout(() => {
    breakpoints.responsiveBreakpointXs = responsiveBreakpointXs(
      window.innerWidth
    );
    breakpoints.responsiveBreakpointSm = responsiveBreakpointSm(
      window.innerWidth
    );
    breakpoints.responsiveBreakpointMd = responsiveBreakpointMd(
      window.innerWidth
    );
    breakpoints.responsiveBreakpointLg = responsiveBreakpointLg(
      window.innerWidth
    );
    breakpoints.responsiveBreakpointXl = responsiveBreakpointXl(
      window.innerWidth
    );
    breakpoints.responsiveBreakpointXxl = responsiveBreakpointXxl(
      window.innerWidth
    );
    breakpoints.responsiveBreakpointXxxl = responsiveBreakpointXxxl(
      window.innerWidth
    );
    breakpoints.responsiveBreakpointHeightMd = responsiveBreakpointHeightMd(
      window.visualViewport ? window.visualViewport.height : window.innerHeight
    );
    breakpoints.responsiveBreakpointHeightXl = responsiveBreakpointHeightXl(
      window.visualViewport ? window.visualViewport.height : window.innerHeight
    );
  }, 50);
});

export default breakpoints;
