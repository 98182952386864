// @ts-nocheck
import { globals } from '../../../../app/src/globals';
import axios from 'axios';
import { HitPdfUtils } from '../../../../hit-components/src/utils/pdf/HitPdfUtils';
import { useNotificationsStore } from '../../store';
import { translate } from '../../plugins/i18n/i18n';
export class StorageService {
    static get baseUrl() {
        return process.env.VUE_APP_STORAGE_URL;
    }
    static get axiosConfig() {
        return {
            headers: {
                Authorization: 'Bearer ' + globals.$keycloak.token,
                'Content-Type': 'application/json',
                'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
            },
        };
    }
    static async fetchFileBuffer(fileId) {
        const config = {
            headers: {
                Authorization: 'Bearer ' + globals.$keycloak.token,
                'Accept-Profile': globals.$keycloak.tokenParsed.schema_id,
            },
            responseType: 'arraybuffer',
        };
        const url = `${this.baseUrl}documents/${fileId}/data`;
        try {
            const response = await axios.get(url, config);
            const contentDisposition = response.headers['content-disposition'];
            const filenameMatch = contentDisposition.match(/filename=['"]?([^'"]+)['"]?/);
            const fileName = filenameMatch && filenameMatch[1]
                ? filenameMatch[1]
                : 'Hit-Online-file';
            // You can use the fileName and response.data (which is the file buffer)
            return { fileName, fileBuffer: response.data };
        }
        catch (error) {
            useNotificationsStore().insertBannerNotification(translate('hit-base.banners.unknown-error-title'));
            return null;
        }
    }
    static downloadFile(fileId) {
        const config = {
            headers: {
                Authorization: 'Bearer ' + globals.$keycloak.token,
                'Accept-Profile': globals.$keycloak.tokenParsed.schema_id,
            },
            responseType: 'arraybuffer',
        };
        const url = `${this.baseUrl}documents/${fileId}/data`;
        return axios
            .get(url, config)
            .then((response) => HitPdfUtils.downloadResponseAsPdf(response))
            .catch(() => {
            useNotificationsStore().insertBannerNotification(translate('hit-base.banners.unknown-error-title'));
        });
    }
    static deleteFile(fileId) {
        const config = this.axiosConfig;
        const url = `${this.baseUrl}documents/${fileId}`;
        return axios.delete(url, config).then((response) => {
            return response;
        });
    }
    static uploadFile(fileId, file, folder) {
        const headers = {
            Authorization: `Bearer ${globals.$keycloak.token}`,
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        formData.append('file', file);
        const url = `${this.baseUrl}documents/${fileId}/data?destination_folder=${folder}`;
        return axios.post(url, formData, { headers });
    }
    static replaceFile(fileId, file) {
        const headers = {
            Authorization: `Bearer ${globals.$keycloak.token}`,
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        formData.append('file', file);
        const url = `${this.baseUrl}documents/${fileId}/data`;
        return axios.patch(url, formData, { headers });
    }
    /**
     * Returns a list with all the different folders that are registered on minio
     */
    static getFolders() {
        const url = `${this.baseUrl}folder/list`;
        return axios.get(url, this.axiosConfig);
    }
    static createCustomFolder(folderName) {
        /**
         * Creates a folder on the storage where the name is prefixed by the custom prefix
         */
        const url = `${this.baseUrl}folder/create/custom/${folderName}`;
        return axios.post(url, {}, this.axiosConfig).catch((error) => {
            if (error && error.response && error.response.status === 444) {
                // 444 is a specific status used to insert banners defined in hit-base translations
                useNotificationsStore().insertBannerNotification(translate(error.response.data));
                return;
            }
            useNotificationsStore().insertBannerNotification(translate('hit-base.banners.unknown-error-title'));
            return;
        });
    }
    static generateInlineUrl(fileId) {
        return `${this.baseUrl}documents/${fileId}/inline`;
    }
}
