import {BaseModel, MultiLanguageDesignationModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';

export default class Qualification extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    active = false,
  }) {
    super({
      id,
      number,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.active = active;
  }

  static get fkName() {
    return 'qualification_id';
  }
  static get apiRoute() {
    return 'qualification';
  }
  static get listPageRoute() {
    return 'roleList';
  }
  static get detailPageRoute() {
    return 'roleList';
  }
  static get createInitColumns() {
    return {};
  }
  static get config() {
    const config = BaseModel.config;
    config['addable'] = false;
    return config;
  }
  get fkName() {
    return Qualification.fkName;
  }
  get apiRoute() {
    return Qualification.apiRoute;
  }
  get detailPageRoute() {
    return Qualification.detailPageRoute;
  }
  get listPageRoute() {
    return Qualification.listPageRoute;
  }
  get config() {
    return Qualification.config;
  }

  static get orderKey() {
    return this.attributeUserLocale('designation');
  }

  static get joinAttrsKey() {
    return [
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
    ];
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
    ]);
  }

  get fullDesignation() {
    return this.designation;
  }

  static get allAttributes() {
    return {
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        creationValue: true,
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '0.1fr',
        },
      }),
    };
  }

  static entityStaffQualificationAttributes(entityType) {
    return {
      qualification: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_QUALIFICATION,
        {
          joinPath: [
            `qualification!fk_${HitUtils.camelToSnake(
              entityType
            )}_staff_qualification_id_qualification`,
          ],
          validations: {required},
          tableProperties: {
            header: 'hit-base.common.role',
            maxWidth: '1fr',
          },
        }
      ),
      staff: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        joinPath: [
          `staff!fk_${HitUtils.camelToSnake(entityType)}_staff_staff_id_staff`,
        ],
        column: 'staff_id',
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.staff',
          maxWidth: '1fr',
        },
      }),
      comment: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.comment',
          maxWidth: '1fr',
        },
      }),
    };
  }
}
