<template>
  <hit-modal
    v-if="visible"
    :popup-size="popupSize"
    cancellable
    :modal-id="'browser.' + route.replaceAll('_', '-')"
  >
    <hit-container
      ref="container"
      :attributes="tableAttributesWithTagFilter"
      :route="route"
      multiple
      :paginate="paginate"
      :default-sort="defaultSort"
      :filter-id="filterId"
      :filter-id-column="filterIdColumn"
      :only-load-active-records="onlyLoadActiveRecords"
      :custom-filters="additionalFilters"
      :init-search-value="initSearchValue"
    >
      <template #default="tableContainer">
        <hit-container-table
          ref="table"
          :container="tableContainer"
          :auto-complete-id="autoCompleteId"
          :addable="addable"
          :deletable="deletable"
          :draggable="draggable"
          :paginate="paginate"
          :items-selectable="selectable"
          :inline-edition-allowed="inlineEditionAllowed"
          :additional-line="additionalLine"
          :data-list-config="dataListConfig"
          :instant-search="true"
          :row-click-to-select="multiple"
          @item-selected="handleItemSelected"
          @add-button-clicked="$emit('addButtonClicked')"
          @editing-item="(id, item) => $emit('editingItem', id, item)"
          @save-line="$emit('saveLine')"
        >
          <template
            v-for="({}, slot) of $slots"
            #[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
              :container="tableContainer"
            />
          </template>
          <template
            v-if="multiple"
            #custom-buttons="{selectedItems}"
          >
            <hit-button
              :label="t('hit-base.common.validate')"
              prefix-icon="check"
              color="accent"
              :disabled="selectedItems.length === 0"
              :show-spinner="insideValidationFunction"
              @click="executeValidationFunction(selectedItems)"
            />
          </template>
        </hit-container-table>
      </template>
    </hit-container>
  </hit-modal>
</template>

<script>
import {HitButton, HitModal} from '@hit/components';
import HitContainerTable from '../../container/HitContainerTable.vue';
import HitContainer from '../../container/HitContainer.vue';
import {nextTick} from 'vue';
import {useI18n} from 'vue-i18n';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '@hit/base';
export default {
  name: 'HitEntityBrowser',
  components: {
    HitContainer,
    HitModal,
    HitContainerTable,
    HitButton,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      insideValidationFunction: false,
    };
  },
  props: {
    /**
     * When setting this flag to true, you can select multiple rows before validating
     */
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableAttributes: {
      type: Object,
      required: true,
    },
    dataListConfig: {
      type: Object,
      default: () => {},
    },
    route: {
      type: String,
      required: true,
    },
    defaultSort: {
      type: Object,
      required: false,
      default() {
        return {
          property: null,
          order: null,
        };
      },
    },
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    customFilters: {
      type: Object,
      required: false,
      default: null,
    },
    visible: {
      type: Boolean,
      required: false,
    },
    autoCompleteId: {
      type: String,
      required: false,
      default: 'default',
    },
    addable: {
      type: Boolean,
      required: false,
      default: false,
    },
    deletable: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemsSelectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    inlineEditionAllowed: {
      type: Boolean,
      required: false,
      default: false,
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    additionalLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    paginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    transformSelectedItemToDbColumnValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    popupSize: {
      type: String,
      required: false,
      default: 'large',
    },
    filterOnTags: {
      type: Array,
      required: false,
      default: null,
    },
    validateFunction: {
      type: Function,
      required: false,
      default: null,
    },
    initSearchValue: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    additionalFilters() {
      if (!this.filterOnTags) return this.customFilters;
      const filters = {...this.customFilters};
      this.filterOnTags.forEach((tagId, index) => {
        filters[`tf${index + 100 + 1}.id`] = `in.(${tagId})`;
      });
      return filters;
    },

    tableAttributesWithTagFilter() {
      if (!this.filterOnTags) return this.tableAttributes;
      const attributes = {...this.tableAttributes};
      this.filterOnTags.forEach((tagId, index) => {
        attributes[`tf${index + 100 + 1}`] = new HitContainerAttribute(
          ATTRIBUTE_TYPES.STRING,
          {
            column: `tag!${this.route}_tag!inner(id)`,
          }
        );
      });
      return attributes;
    },

    /**
     * We need to activate the checkboxes for different use-cases
     */
    selectable() {
      return this.itemsSelectable || this.multiple;
    },
  },
  methods: {
    handleItemSelected(item) {
      let itemToEmit = item;
      if (this.transformSelectedItemToDbColumnValues) {
        for (let attributeKey in this.tableAttributes) {
          if (
            this.tableAttributes[attributeKey].dataType === ATTRIBUTE_TYPES.TAGS
          ) {
            delete itemToEmit[attributeKey];
            continue;
          }
          if (
            this.tableAttributes[attributeKey] &&
            this.tableAttributes[attributeKey].column
          ) {
            let columnName =
              this.tableAttributes[attributeKey].formItemName ||
              this.tableAttributes[attributeKey].column;
            itemToEmit[columnName] = itemToEmit[attributeKey];
            if (columnName !== attributeKey) {
              delete itemToEmit[attributeKey];
            }
          }
        }
      }
      this.$emit('itemSelected', itemToEmit);
    },

    /**
     * By passing the function to the component and executing it here, we can
     * handle the spinner inside this component without passing more props
     */
    executeValidationFunction(selectedItems) {
      this.insideValidationFunction = true;
      if (!this.validateFunction) {
        this.$emit('validate-selection', selectedItems);
        return;
      }
      this.validateFunction(selectedItems).then(() => {
        this.insideValidationFunction = false;
        this.$emit('afterValidation');
      });
    },
  },
  watch: {
    visible(newValue) {
      if (newValue === true) {
        nextTick(() => {
          const searchBar = document.getElementById('search');
          if (searchBar) {
            searchBar.focus();
          }
        });
      }
    },
  },
};
</script>
