import {MultiLanguageDesignationModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {translate} from '../../plugins/i18n/i18n';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';

export default class Activity extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    target = undefined,
  }) {
    super({
      id,
      number,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.target = target
      ? HitOnlineEnums.getEnumValue('ACTIVITY_TARGET', target, translate)
      : null;
  }

  static get fkName() {
    return 'activity_id';
  }
  static get apiRoute() {
    return 'activity';
  }
  static get listPageRoute() {
    return 'activityList';
  }
  static get detailPageRoute() {
    return 'activityList';
  }
  static get createInitColumns() {
    return {};
  }
  get fkName() {
    return Activity.fkName;
  }
  get apiRoute() {
    return Activity.apiRoute;
  }
  get detailPageRoute() {
    return Activity.detailPageRoute;
  }
  get listPageRoute() {
    return Activity.listPageRoute;
  }
  static get config() {
    return {
      addable: false,
      duplicatable: false,
      importable: false,
      browsable: false,
      deletable: false,
    };
  }

  get config() {
    return Activity.config;
  }

  static get joinAttrsKey() {
    return [
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'target',
    ];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'target',
      'active',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'target',
    ]);
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.5fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        creationValue: true,
        tableProperties: {
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
      target: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'ACTIVITY_TARGET',
        column: 'target',
        tableProperties: {
          header: 'hit-app.common.target',
          maxWidth: '0.5fr',
        },
      }),
    };
  }
}
