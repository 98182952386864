import {Language, useConfigurationStore} from '@hit/base';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';

export class HitLanguageUtils extends HitUtils {
  // TODO:  update this list when a new language is added (all the translations)
  static SUPPORTED_LANGUAGES = [
    {
      id: 'de_be',
      locale: 'de-BE',
      designation: 'Deutsch (Belgien)',
      flag: 'DE',
    },
    {
      id: 'en_gb',
      locale: 'en-GB',
      designation: 'English (British)',
      flag: 'GB',
    },
    {
      id: 'fr_be',
      locale: 'fr-BE',
      designation: 'Français (Belgique)',
      flag: 'FR',
    },
    {
      id: 'nl_be',
      locale: 'nl-BE',
      designation: 'Nederlands (België)',
      flag: 'NL',
    },
    {id: 'mr_in', locale: 'mr-IN', designation: 'Marathi (India)', flag: 'IN'},
    {id: 'hi_in', locale: 'hi-IN', designation: 'Hindi (India)', flag: 'IN'},
  ];

  /**
   * Returns all the supported languages in the HitSelect options prop format
   */
  static getSupportedLanguageOptions() {
    return this.SUPPORTED_LANGUAGES;
  }

  /**
   * Returns all european supported languages in the HitSelect options prop format
   */
  static getSupportedEuropeanLanguageOptions() {
    return this.SUPPORTED_LANGUAGES.filter(
      (language) => language.id !== 'mr_in' && language.id !== 'hi_in'
    ); //MR_IN & HI_IN needs to be implemented
  }

  /**
   * Returns the language in HitSelect format where the id is in the languageIds parameter
   */
  static getAvailableLanguageOptions(languageIds) {
    return this.SUPPORTED_LANGUAGES.filter((lan) =>
      languageIds.includes(lan.id)
    );
  }

  /**
   * Returns the locale name of the selected language: en_gb -> en-GB
   */
  static getLocale(language) {
    return this.SUPPORTED_LANGUAGES.find((lan) => lan.id === language).locale;
  }

  /**
   * Adds the locale suffix to the string. We can choose between user and company language
   */
  static localizeString(string, localeType = 'user', type = 'snakeCase') {
    switch (localeType) {
      case 'user':
        if (type === 'snakeCase') {
          return `${string}_${useConfigurationStore().userLanguageSnakeCase}`;
        } else {
          return `${string}${useConfigurationStore().userLanguagePascalCase}`;
        }
      case 'company':
        if (type === 'snakeCase') {
          return `${string}_${useConfigurationStore().mainLanguageSnakeCase}`;
        } else {
          return `${string}${useConfigurationStore().mainLanguagePascalCase}`;
        }
      default:
        throw Error('The type needs to be either user or company');
    }
  }

  static localeOptions() {
    const locales = useConfigurationStore().companyLanguagesSnakeCase;
    return locales.map((l) => new Language(l));
  }
}

function longDesignationFromId(id) {
  switch (id.replace('-', '_').toLowerCase()) {
    case 'de_be':
      return 'Deutsch';
    case 'nl_be':
      return 'Nederlands';
    case 'fr_be':
      return 'Français';
    case 'mr_in':
      return 'मराठी';
    case 'en_gb':
      return 'English';
  }
}

export function parseLocale(locale) {
  let localeBase = locale.replace('-', '').replace('_', '');
  let localeSnakeCase =
    localeBase.charAt(0).toLowerCase() +
    localeBase.charAt(1).toLowerCase() +
    '_' +
    localeBase.charAt(2).toLowerCase() +
    localeBase.charAt(3).toLowerCase();
  let localeCamelCase =
    localeBase.charAt(0).toUpperCase() +
    localeBase.charAt(1).toLowerCase() +
    localeBase.charAt(2).toUpperCase() +
    localeBase.charAt(3).toLowerCase();
  let localeStandardCase =
    localeBase.charAt(0).toLowerCase() +
    localeBase.charAt(1).toLowerCase() +
    '-' +
    localeBase.charAt(2).toUpperCase() +
    localeBase.charAt(3).toUpperCase();
  return {
    id: localeSnakeCase,
    idApp: localeCamelCase,
    shortDesignation: localeStandardCase,
    longDesignation: longDesignationFromId(localeSnakeCase),
  };
}

export function generateColumnForAllCompanyLanguages(column) {
  const generatedColumnNames = [];
  useConfigurationStore().companyLanguagesSnakeCase.forEach((language) => {
    generatedColumnNames.push(`${column}${parseLocale(language).idApp}`);
  });
  return generatedColumnNames;
}
