import {BaseModel, MultiLanguageDesignationModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';

export default class DayType extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    workingAllowed = undefined,
    active = undefined,
  }) {
    super({
      id,
      number,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.workingAllowed = workingAllowed;
    this.active = active;
  }

  static get fkName() {
    return 'day_type_id';
  }
  static get apiRoute() {
    return 'day_type';
  }
  static get listPageRoute() {
    return 'dayTypeList';
  }
  static get detailPageRoute() {
    return 'dayTypeList';
  }
  static get createInitColumns() {
    return {};
  }
  get fkName() {
    return DayType.fkName;
  }
  get apiRoute() {
    return DayType.apiRoute;
  }
  get detailPageRoute() {
    return DayType.detailPageRoute;
  }
  get listPageRoute() {
    return DayType.listPageRoute;
  }
  static get config() {
    return {
      addable: false,
      duplicatable: false,
      importable: false,
      browsable: false,
      deletable: false,
    };
  }

  get config() {
    return DayType.config;
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'workingAllowed',
      'active',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'workingAllowed',
    ]);
  }

  static get joinAttrsKey() {
    return [
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'workingAllowed',
    ];
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.5fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      workingAllowed: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'working_allowed',
        tableProperties: {
          header: 'hit-app.configuration.working-allowed',
          maxWidth: '0.5fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
    };
  }
}
