import BaseModel from './BaseModel';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {Country} from './index';

export default class Project extends BaseModel {
  constructor({
    id,
    number,
    designation,
    active = true,
    tags = [],
    street = undefined,
    houseNo = undefined,
    boxNo = undefined,
    postcode = undefined,
    city = undefined,
    country = undefined,
    memo = undefined,

    // We also need to add the key how it is stored in the db (we use the db column names)
    no = undefined,
  }) {
    super(id, number || no, designation);
    this.active = active;
    this.street = street;
    this.houseNo = houseNo;
    this.boxNo = boxNo;
    this.postcode = postcode;
    this.city = city;
    this.country = country ? new Country(country) : null;
    this.memo = memo;
  }

  static get fkName() {
    return 'project_id';
  }
  static get apiRoute() {
    return 'project';
  }
  static get detailPageRoute() {
    return 'projectDetail';
  }
  static get listPageRoute() {
    return 'projectList';
  }
  get fkName() {
    return Project.fkName;
  }
  get apiRoute() {
    return Project.apiRoute;
  }
  get listPageRoute() {
    return Project.listPageRoute;
  }
  get detailPageRoute() {
    return Project.detailPageRoute;
  }
  static get attrsToStoreInForm() {
    return ['number', 'designation'];
  }
  get attrsToStoreInForm() {
    return BaseModel.attrsToStoreInForm;
  }

  static get joinAttrsKey() {
    return ['number', 'designation'];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'customers',
      'street',
      'houseNo',
      'boxNo',
      'postcode',
      'city',
      'country',
      'active',
      'tags',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      ['number', 'designation', 'customers', 'active', 'tags'],
      ['street', 'houseNo', 'boxNo', 'postcode', 'city', 'country']
    );
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          maxWidth: '2fr',
        },
      }),
      customers: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'customer',
        tableProperties: {
          header: 'hit-app.common.customers',
          maxWidth: '2fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
          maxWidth: '1fr',
        },
      }),
      street: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.street',
        },
      }),
      houseNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'house_no',
        tableProperties: {
          header: 'hit-app.common.house-number',
        },
      }),
      boxNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'box_no',
        tableProperties: {
          header: 'hit-app.common.box-number',
        },
      }),
      postcode: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.postcode',
        },
      }),
      city: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.city',
        },
      }),
      country: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_COUNTRY, {
        joinPath: ['country!fk_project_country_country'],
        tableProperties: {
          header: 'hit-base.common.country',
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
    };
  }
}
