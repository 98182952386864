<template>
  <hit-form
    ref="hitForm"
    :value="formItem"
    :autosave="autosave"
    :autocomplete="autocomplete"
    :validations="validations"
    :cancellable="cancellable"
    :save-label="isUpdate ? saveLabel : createLabel || saveLabel"
    :save-icon="isUpdate ? 'save' : 'add'"
    :cancel-label="cancelLabel"
    :invalid-save-allowed="invalidSaveAllowed"
    :render-as-table="renderAsTable"
    :touch-validation-state="touchValidationState"
    :fixed-label-width="fixedLabelWidth"
    :before-save-fn="beforeSaveFn"
    @cancel="onFormCancel"
    @save="onFormSave"
  >
    <template #default="{formData, validationState, manualSave, draftFields}">
      <slot
        :form-data="formData"
        :validation-state="validationState"
        :save-form="manualSave"
        :draft-fields="draftFields"
      />
    </template>
  </hit-form>
</template>

<script>
import {HitForm, HitLoadingEmitterMixin} from '@hit/components';
import {translate} from '../../../../plugins/i18n/i18n';

export default {
  name: 'HitAppForm',
  components: {HitForm},
  mixins: [HitLoadingEmitterMixin],
  props: {
    /**
     * Value of the form
     */
    value: {
      // update: {id: myid}
      // create: {field1: "init value 1", field2: "init value 2"}
      type: Object,
      required: true,
    },
    /**
     * Enable autosave
     */
    autosave: Boolean,
    /**
     * Enable autocomplete
     */
    autocomplete: Boolean,
    /**
     * Enable cancel button of the form
     */
    cancellable: Boolean,
    /**
     * Label of the save button
     */
    saveLabel: {
      type: String,
      default() {
        return translate('hit-components.common.save');
      },
    },
    /**
     * Label of the create button
     */
    createLabel: {
      type: String,
      default() {
        return translate('hit-components.common.add');
      },
    },
    /**
     * Label of the cancel button
     */
    cancelLabel: {
      type: String,
      default() {
        return translate('hit-components.common.cancel');
      },
    },
    /**
     * Store namespace
     */
    storeNamespace: {
      type: String,
      required: true,
    },
    /**
     * Fields to fetch
     */
    fields: {
      type: Array,
      default: () => [],
    },
    /**
     * Store update action
     */
    storeUpdate: {
      type: String,
      default: 'updateItem',
    },
    /**
     * Store create action
     */
    storeCreate: {
      type: String,
      default: 'addItem',
    },
    /**
     * Parent ID
     */
    parentId: {
      type: String,
      default: null,
    },
    /**
     * Validations of the form
     */
    validations: {
      type: Object,
      default: () => {},
    },
    /**
     * Creation route name
     */
    creationRouteName: {
      type: String,
      default: null,
    },
    /**
     * Enable save when form is invalid
     */
    invalidSaveAllowed: Boolean,
    /**
     * Enable hiding of the loading
     */
    hideLoading: Boolean,
    /**
     * Enable handling of the loading in the table
     */
    handleLoading: Boolean,
    /**
     * Render table as form
     */
    renderAsTable: Boolean,
    /**
     * Enable touch of the validationState
     */
    touchValidationState: Boolean,
    /**
     * Enable fixed label width
     */
    fixedLabelWidth: {
      type: Boolean,
      default: true,
    },
    /**
     * Function to be executed before saving to add additional fields or override existing
     */
    beforeSaveFn: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isSaveItemLoading: false,
      fetchItemQueryId: null,
    };
  },
  computed: {
    hitLoading() {
      return this.hideLoading ? false : this.isFetchItemLoading;
    },
    itemId() {
      return this.value.id;
    },
    isCreation() {
      return !this.itemId;
    },
    isUpdate() {
      return !this.isCreation;
    },
    formItem() {
      if (this.isUpdate) {
        if (this.isFetchItemLoading) {
          return undefined;
        } else {
          return this.$store.getters[`${this.storeNamespace}/item`](
            this.value.id
          );
        }
      } else {
        return this.value;
      }
    },
    isFetchItemLoading() {
      return (
        this.fetchItemParams &&
        (!this.fetchItemQueryId ||
          this.$store.getters[`${this.storeNamespace}/itemsLoading`](
            this.fetchItemQueryId
          ))
      );
    },
    fetchItemParams() {
      if (this.itemId) {
        return {
          query: {
            id: this.itemId,
            parentId: this.parentId,
            fields: this.fields,
          },
        };
      } else {
        return null;
      }
    },
    isFormSaving() {
      if (this.autosave) {
        return this.isSaveItemLoading;
      } else {
        return undefined;
      }
    },
  },
  watch: {
    fetchItemParams: {
      immediate: true,
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.fetchItem();
        }
      },
    },
    isFetchItemLoading: {
      immediate: true,
      handler(newValue) {
        if (this.hideLoading) {
          this.setLoading(newValue);
        }
      },
    },
  },
  methods: {
    onFormCancel() {
      /**
       * When the modifications are cancelled
       */
      return this.$emit('cancel');
    },
    async onFormSave(data) {
      this.isSaveItemLoading = true;
      try {
        let dataToSend = {};
        let storeAction;
        if (this.isCreation) {
          storeAction = this.storeCreate;
          Object.assign(dataToSend, this.value); // init values by default
        } else {
          storeAction = this.storeUpdate;
          dataToSend.id = this.value.id;
        }
        Object.assign(dataToSend, data);

        let resultItem = await this.$store.dispatch(
          `${this.storeNamespace}/${storeAction}`,
          {parentId: this.parentId, item: dataToSend}
        );
        if (this.isCreation && this.creationRouteName) {
          this.$router.push({
            name: this.creationRouteName,
            params: {id: resultItem.id},
          });
        }

        if (this.isCreation) {
          this.$emit('created', {id: resultItem.id});
        } else {
          this.$emit('updated', {id: resultItem.id});
        }
      } finally {
        this.isSaveItemLoading = false;
      }
    },
    async fetchItem() {
      if (this.fetchItemParams) {
        this.fetchItemQueryId = await this.$store.dispatch(
          `${this.storeNamespace}/fetchItem`,
          this.fetchItemParams
        );
      } else {
        this.fetchItemQueryId = null;
      }
    },
  },
};
</script>
