<template>
  <hit-table
    ref="table"
    :items="dataToDisplay"
    :auto-complete-id="autoCompleteId"
    :item-properties="container.tableProperties"
    :validations="container.validations"
    :create-item-fn="container.createItemFn"
    :item-id-property="container.idColumn"
    :before-save-fn="beforeSaveFn"
    :paginator="paginate"
    :auto-row-height="autoRowHeight"
    :addable="addable"
    :printable="printable"
    :deletable="deletable"
    :deletable-fn="deletableFn"
    :sharable="sharable"
    :custom-column-width="customColumnWidth"
    :custom-column-alignment="customColumnAlignment"
    custom-search
    defer-sorting
    :additional-line="additionalLine"
    :config-store="configStore"
    :force-search="forceSearch"
    :custom-max-height="customMaxHeight"
    :items-per-page="itemsPerPage"
    :user-store="user"
    :initial-search-value="container.searchValue"
    :user-filters="container.userFilters"
    :search-field="container.searchField"
    :data-service="dataService"
    :custom-sort="container.customSort"
    @save="container.handleChange"
    @saveLine="container.handleChange"
    @insertLine="container.handleInsert"
    @editItem="container.handleEdit"
    @delete="container.handleDelete"
    @search="container.searchFunction"
    @move="container.moveItem"
    @sort="container.sort"
    @share="openShareDialog"
    @change-filter="container.handleChangeUserFilter"
    @print="printItems"
  >
    <template #pagination>
      <div class="grid items-center grid-flow-col text-table">
        <div class="mr-2">
          <hit-select
            :inline-input="true"
            :options="itemsPerPageOptions"
            :clearable="false"
            :filterable="false"
            :value="itemsPerPage"
            :division-line="false"
            @change="(id) => setItemsPerPage(id)"
          />
        </div>
        <hit-icon
          icon="double-left"
          :clickable="currentPage > 0"
          @click="setSelectedPage(0)"
        />
        <hit-icon
          icon="expand-left"
          :clickable="currentPage > 0"
          @click="setSelectedPage(currentPage - 1)"
        />
        <span>{{ noPages ? currentPage + 1 + '/' + noPages : '' }}</span>
        <hit-icon
          icon="expand-right"
          :clickable="currentPage + 1 < noPages"
          @click="setSelectedPage(currentPage + 1)"
        />
        <hit-icon
          icon="double-right"
          :clickable="currentPage + 1 < noPages"
          @click="setSelectedPage(noPages - 1)"
        />
      </div>
    </template>
    <template #dialogZone>
      <share-dialog
        :visible="showShareDialog"
        :shared-entity="container.route"
        :sharable-columns="columnsToShare"
        :shared-records="itemsToShare"
        @cancel="closeShareDialog"
        @shared="dataSharedWithSuccess"
      />
      <hit-app-print-config
        v-if="showPrintDialog"
        :ids-to-print="itemsToPrint.map((item) => item.id)"
        :template-type="printTemplateType"
        @cancel="showPrintDialog = false"
        @printed="resetAfterPrint"
      />
    </template>
    <template
      v-for="({}, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </hit-table>
</template>

<script>
import {HitSelect, HitTable} from '@hit/components';
import HitIcon from '@hit/components/src/components/icon/HitIcon.vue';
import {ATTRIBUTE_TYPES} from './HitContainerAttribute';
import {Decimal} from 'decimal.js';
import {
  DataService,
  useConfigurationStore,
  useUserProfileStore,
} from '@hit/base';
import shareDialog from 'hit-online-web-ui/src/components/hit/dialog/ShareDialog.vue';
import {inject} from 'vue';
import HitAppPrintConfig from 'hit-online-web-ui/src/components/app/print/HitAppPrintConfig.vue';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitContainerTable',
  components: {
    HitAppPrintConfig,
    shareDialog,
    HitIcon,
    HitTable,
    HitSelect,
  },

  setup() {
    const user = useUserProfileStore();
    const {t} = useI18n();
    return {t, user};
  },

  props: {
    container: {
      type: Object,
      required: true,
    },
    paginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    autoRowHeight: {
      type: Boolean,
      default: false,
    },
    addable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
    },
    deletableFn: {
      type: Function,
      default: null,
    },
    sharable: {
      type: Boolean,
      default: false,
    },
    autoCompleteId: {
      type: String,
      required: false,
      default: 'default',
    },
    additionalLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    customColumnWidth: {
      type: String,
      required: false,
      default: '1fr',
    },
    customColumnAlignment: {
      type: String,
      required: false,
      default: 'end',
      validator: (value) => {
        return ['end', 'center', 'start'].indexOf(value) !== -1;
      },
    },
    /**
     * Used to add columns to the sharable list that make not part of the HitContainerAttributes because they are not displayed
     */
    supplementarySharableColumns: {
      type: Object,
      required: false,
      default: () => {},
    },
    forceSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    beforeSaveFn: {
      type: Function,
      default() {
        return null;
      },
    },
    /**
     * Type of the template the user can select from
     */
    printTemplateType: {
      type: String,
      required: false,
      default: null,
    },
    customMaxHeight: String,
  },

  data() {
    return {
      configStore: useConfigurationStore(),
      showShareDialog: false,
      dataToShare: [],
      tableName: undefined,
      showPrintDialog: false,
      itemsToPrint: [],
    };
  },

  computed: {
    dataService() {
      return DataService;
    },
    /**
     * Activates the print button in the table once a template type is defined
     */
    printable() {
      return !!this.printTemplateType;
    },
    columnsToShare() {
      const sharableColumns = {};
      for (let key in this.container.attributes) {
        const attribute = this.container.attributes[key];
        if (attribute.tableProperties?.sharable) {
          sharableColumns[attribute.column ? attribute.column : key] =
            attribute.tableProperties.header;
        }
      }
      return Object.assign(sharableColumns, this.supplementarySharableColumns);
    },
    itemsToShare() {
      return this.dataToShare.map((item) => item.id);
    },
    itemsPerPage() {
      return this.container.paginationData.itemsPerPage;
    },
    itemsPerPageOptions() {
      return this.container.paginationData.itemsPerPageOptions;
    },
    currentPage() {
      return this.container.paginationData.currentPage;
    },
    noPages() {
      return this.container.paginationData.noPages;
    },
    dataToDisplay() {
      let decimalAttributes = [];
      for (let attributeKey in this.container.attributes) {
        if (
          this.container.attributes[attributeKey].dataType ===
          ATTRIBUTE_TYPES.DECIMAL
        ) {
          decimalAttributes.push(attributeKey);
        }
      }
      return this.container.data.map((row) => {
        let shallowCopy = {...row};
        for (let attributeKey of decimalAttributes) {
          if (Decimal.isDecimal(shallowCopy[attributeKey])) {
            shallowCopy[attributeKey] = shallowCopy[attributeKey].toFixed(
              this.container.attributes[attributeKey].decimalPrecision
            );
          } else {
            shallowCopy[attributeKey] = new Decimal(
              shallowCopy[attributeKey]
            ).toFixed(this.container.attributes[attributeKey].decimalPrecision);
          }
        }
        return shallowCopy;
      });
    },
  },
  beforeMount() {
    const panelId = inject('panelId');
    if (panelId) {
      this.tableName = panelId;
      const config = useConfigurationStore();
      const pagination = config.getConfiguration(`${panelId}.pagination`);
      const selectedPage = config.getTablePage(panelId);
      this.container.setItemsPerPage(pagination);
      this.container.setCurrentPage(selectedPage);
    }
  },
  methods: {
    /**
     * METHODS CALLED FROM THE PARENT COMPONENT
     */
    addNewItem() {
      // Adds a new item to the table from the parent component
      this.$refs.table.addNewItem();
    },
    closeEditItem() {
      // Closes the line in edition mode (without saving except autosave is on)
      this.$refs.table.editingItemId = null;
    },
    triggerEditItem(itemId) {
      // Enables the edit mode of one item from the parent component
      this.$refs.table.editingItemId = itemId;
    },

    setItemsPerPage(pagination) {
      this.container.setItemsPerPage(pagination);
      this.configStore.updateHiddenConfiguration(
        `${this.tableName}.pagination`,
        pagination
      );
    },
    setSelectedPage(newPageIndex) {
      this.container.setCurrentPage(newPageIndex);
      this.configStore.setTablePage(this.tableName, newPageIndex);
    },
    printItems(itemsToPrint) {
      this.itemsToPrint = itemsToPrint;
      this.showPrintDialog = true;
    },
    resetAfterPrint() {
      this.showPrintDialog = false;
      this.$refs.table.resetSelectedItems();
    },
    openShareDialog(data) {
      this.dataToShare = data;
      this.showShareDialog = true;
    },
    closeShareDialog() {
      this.showShareDialog = false;
    },
    dataSharedWithSuccess() {
      this.$refs.table.resetSelectedItems();
      this.showShareDialog = false;
    },
    getSelectedItems() {
      return this.$refs.table.getSelectedItems();
    },
  },
};
</script>
