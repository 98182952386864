<template>
  <span
    :class="[
      redirectionPossible
        ? 'hover:underline cursor-pointer text-clickable'
        : '',
    ]"
    @click.stop="redirectTo"
    @mousedown.middle.stop="openInNewTab"
  >
    {{ entity.entityDesignation }}
  </span>
</template>

<script>
export default {
  name: 'HitRedirectToEntity',
  props: {
    /**
     * Entity that is displayed and redirected to if clicked on label
     */
    entity: {
      type: Function,
      required: true,
    },
  },

  computed: {
    redirectionPossible() {
      return this.entity?.detailPageRoute && this.entity?.id;
    },
  },

  methods: {
    redirectTo() {
      if (this.redirectionPossible) {
        this.$router.push({
          name: this.entity.detailPageRoute,
          params: {
            id: this.entity.id,
          },
        });
      }
    },

    openInNewTab() {
      const routeData = this.$router.resolve({
        name: this.entity.detailPageRoute,
        params: {
          id: this.entity.id,
        },
      });
      const a = document.createElement('a');
      a.href = routeData.fullPath;
      a.target = '_blank';
      const e = new MouseEvent('click', {
        ctrlKey: true, // for Windows or Linux
        metaKey: true, // for MacOS
      });
      a.dispatchEvent(e);
    },
  },
};
</script>
