import {HitUtils} from '../hit/HitUtils';

export class HitPdfUtils extends HitUtils {
  /**
   * Downloads the response sent by an API as a PDF
   */
  static downloadResponseAsPdf(response) {
    let fileType = response.headers['content-type'];
    let filename = response.headers['content-disposition'].slice(21);
    filename = filename.slice(1, filename.length - 1);
    let arrayBuffer = response.data;
    const blob = new Blob([arrayBuffer], {type: fileType});
    const fakeUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fakeUrl;
    a.download = filename.replace(/^"|"$/g, ''); // Remove " before/after filename like "streamlitAdminReporting.json";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(fakeUrl);
  }
}
