class UserCompany {
    usernameEmail;
    password;
    firstName;
    lastName;
    countryCode;
    vatNumber;
    userSystemLanguage;
    designationLegal;
    street;
    houseNumber;
    boxNumber;
    postCode;
    city;
    billingEmail;
    phone;
    mobile;
    rightsAccepted;
    dateRequest;
    checkVatNumberBeforeAddAccount;
    confirmPassword;
    activity;
    referralCode;
    constructor(usernameEmail, password, firstName, lastName, countryCode, vatNumber, userSystemLanguage, designationLegal, street, houseNumber, boxNumber, postCode, city, billingEmail, phone, mobile, rightsAccepted, dateRequest, checkVatNumberBeforeAddAccount, confirmPassword, activity, referralCode) {
        this.usernameEmail = usernameEmail;
        this.password = password;
        this.firstName = firstName;
        this.lastName = lastName;
        this.countryCode = countryCode;
        this.vatNumber = vatNumber;
        this.userSystemLanguage = userSystemLanguage;
        this.designationLegal = designationLegal;
        this.street = street;
        this.houseNumber = houseNumber;
        this.boxNumber = boxNumber;
        this.postCode = postCode;
        this.city = city;
        this.billingEmail = billingEmail;
        this.phone = phone;
        this.mobile = mobile;
        this.rightsAccepted = rightsAccepted;
        this.dateRequest = dateRequest;
        this.checkVatNumberBeforeAddAccount = checkVatNumberBeforeAddAccount;
        this.confirmPassword = confirmPassword;
        this.activity = activity;
        this.referralCode = referralCode;
    }
}
export function getFakeAccount() {
    const varOut = fakeAccounts[0];
    return varOut;
}
const fakeAccounts = [
    new UserCompany('m.maes@ficos.com', 'password', 'Maxime', 'Maes', 'LU', 'LU35205156', 'fr', 'Ficos SARL (createtest)', 'Place Nicolas Adames', '1', '', 'L-9912', 'Troisvierges', 'm.maes@ficos.com', '+35220882023', null, true, new Date(), true, 'johnpassword123', 'activity', 'abcdef'),
];
