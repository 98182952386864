<template>
  <hit-address-browser v-if="browserType === 'address'" />
  <hit-staff-browser v-else-if="browserType === 'staff'" />
  <hit-equipment-browser v-else-if="browserType === 'equipment'" />
  <hit-resource-browser v-else-if="browserType === 'resource'" />
  <hit-project-browser v-else-if="browserType === 'project'" />
  <hit-project-part-browser v-else-if="browserType === 'projectPart'" />
  <hit-unit-browser v-else-if="browserType === 'unit'" />
  <hit-period-browser v-else-if="browserType === 'period'" />
  <hit-file-browser v-else-if="browserType === 'file'" />
  <hit-journal-browser v-else-if="browserType === 'journal'" />
  <hit-action-browser v-else-if="browserType === 'action'" />
  <hit-transaction-browser v-else-if="browserType === 'transaction'" />
  <hit-tax-code-browser v-else-if="browserType === 'taxCode'" />
  <hit-due-date-browser v-else-if="browserType === 'dueDate'" />
  <hit-purchase-browser v-else-if="browserType === 'purchase'" />
</template>

<script>
import HitAddressBrowser from '../browser/HitAddressBrowser.vue';
import HitStaffBrowser from '../browser/HitStaffBrowser.vue';
import HitResourceBrowser from '../browser/HitResourceBrowser.vue';
import HitEquipmentBrowser from '../browser/HitEquipmentBrowser.vue';
import HitProjectBrowser from '../browser/HitProjectBrowser.vue';
import HitProjectPartBrowser from '../browser/HitProjectPartBrowser.vue';
import HitUnitBrowser from '../browser/HitUnitBrowser.vue';
import HitPeriodBrowser from '../browser/HitPeriodBrowser.vue';
import HitFileBrowser from '../browser/HitFileBrowser.vue';
import HitJournalBrowser from '../browser/HitJournalBrowser.vue';
import HitActionBrowser from '../browser/HitActionBrowser.vue';
import HitTransactionBrowser from './HitTransactionBrowser.vue';
import HitTaxCodeBrowser from './HitTaxCodeBrowser.vue';
import HitDueDateBrowser from './HitDueDateBrowser.vue';
import HitPurchaseBrowser from './HitPurchaseBrowser.vue';

export default {
  name: 'HitDynamicBrowser',
  components: {
    HitActionBrowser,
    HitAddressBrowser,
    HitStaffBrowser,
    HitResourceBrowser,
    HitEquipmentBrowser,
    HitProjectBrowser,
    HitProjectPartBrowser,
    HitUnitBrowser,
    HitPeriodBrowser,
    HitFileBrowser,
    HitJournalBrowser,
    HitTransactionBrowser,
    HitTaxCodeBrowser,
    HitDueDateBrowser,
    HitPurchaseBrowser,
  },
  props: {
    /**
     * String defining the type of entity browser we need to display
     */
    browserType: {
      type: String,
      required: true,
    },
  },
};
</script>
