import {defineStore} from 'pinia';
import axios from 'axios';
import {globals} from 'hit-online-web-ui/src/globals';
import {ActionService, DataService} from '@hit/base';
import {v4 as uuidv4} from 'uuid';
import {HitUUIDUtils} from '@hit/components';

function parseLocale(locale, format = 'PascalCase') {
  const localeBase = (locale = locale.replace('_', '').replace('-', ''));
  if (locale.length !== 4) {
    throw new Error('Impossible to parse locale. Invalid number of characters');
  }
  if (format === 'PascalCase') {
    return (
      localeBase[0].toUpperCase() +
      localeBase[1].toLowerCase() +
      localeBase[2].toUpperCase() +
      localeBase[3].toLowerCase()
    );
  } else if (format === 'snake_case') {
    return (
      localeBase[0].toLowerCase() +
      localeBase[1].toLowerCase() +
      '_' +
      localeBase[2].toLowerCase() +
      localeBase[3].toLowerCase()
    );
  } else if (format === 'camelCase') {
    return (
      localeBase[0].toLowerCase() +
      localeBase[1].toLowerCase() +
      localeBase[2].toUpperCase() +
      localeBase[3].toLowerCase()
    );
  } else if (format === 'id') {
    return (
      localeBase[0].toLowerCase() +
      localeBase[1].toLowerCase() +
      '-' +
      localeBase[2].toUpperCase() +
      localeBase[3].toUpperCase()
    );
  } else {
    throw new Error(
      'Locale parser does only support the following formats: PascalCase, snake_case and camelCase'
    );
  }
}

function parseDesignation(locale, format = 'short') {
  const localePascalCase = parseLocale(locale, 'PascalCase');
  switch (localePascalCase) {
    case 'EnGb':
      return format === 'short' ? 'En-GB' : 'English (United Kingdom)';
    case 'FrBe':
      return format === 'short' ? 'Fr-BE' : 'Français (Belgique)';
    case 'NlBe':
      return format === 'short' ? 'Nl-BE' : 'Nederlands (België)';
    case 'DeBe':
      return format === 'short' ? 'De-BE' : 'Deutsch (Belgien)';
    case 'MrIn':
      return format === 'short' ? 'Mr-IN' : 'Marathi (India)';
  }
}

function getDefaultValue(configLabel) {
  if (configLabel.includes('collapsed')) {
    return false;
  } else if (configLabel.includes('columns')) {
    return [];
  } else if (configLabel.includes('pagination')) {
    return 20;
  } else {
    return {};
  }
}

function getConfigDatatype(configLabel) {
  if (configLabel.includes('collapsed')) {
    return 'BOOLEAN';
  } else if (configLabel.includes('columns')) {
    return 'JSON';
  } else if (configLabel.includes('pagination')) {
    return 'INT';
  } else {
    return 'JSON';
  }
}

export const useConfigurationStore = defineStore('configuration', {
  state: () => ({
    configurations: {},
    tablePageConfigurations: {},
    customButtons: {},
    tableFilters: {},
    tableSorting: {},
    favoriteScreens: [],
    hiddenPanels: {},
  }),

  getters: {
    configuration: (state) => state.configurations,
    favorites: (state) => state.favoriteScreens,
    userLanguage: (state) => {
      /**
       * Returns the UI language of the user in the original format
       * => en-GB
       */
      return state.configurations['user.locale'];
    },
    userLanguageSnakeCase: (state) => {
      /**
       * Returns the selected UI language in snake_case format
       * => en_gb
       */
      return parseLocale(state.configurations['user.locale'], 'snake_case');
    },
    userLanguagePascalCase: (state) => {
      /**
       * Returns the selected UI language in PascalCase format
       * => EnGb
       */
      return parseLocale(state.configurations['user.locale'], 'PascalCase');
    },
    mainLanguageSnakeCase: (state) => {
      /**
       * Returns the first language of the company in snake_case format
       * company.locales = ['en_gb', 'fr_be']
       * => en_gb
       */
      return parseLocale(
        state.configurations['company.locales'][0],
        'snake_case'
      );
    },
    mainLanguagePascalCase: (state) => {
      /**
       * Returns the first language of the company in PascalCase format
       * company.locales = ['en_gb', 'fr_be']
       * => EnGb
       */
      return parseLocale(
        state.configurations['company.locales'][0],
        'PascalCase'
      );
    },
    companyLanguagesSnakeCase: (state) => {
      /**
       * Returns all the company locales in the snake_case format = original format from the database
       * company.locales = ['en_gb', 'fr_be']
       * => ['en_gb', 'fr_be']
       */
      return state.configurations['company.locales'];
    },
    companyLanguagesPascalCase: (state) => {
      /**
       * Returns all the company locales in the PascalCase format
       * company.locales = ['en_gb', 'fr_be']
       * => ['EnGb', 'FrBe']
       */
      return state.configurations['company.locales'].map((locale) =>
        parseLocale(locale, 'PascalCase')
      );
    },
    companyLanguagesObject: (state) => {
      const companyLanguages = [];
      state.configurations['company.locales'].forEach((language) => {
        companyLanguages.push({
          id: parseLocale(language, 'id'),
          valueSuffix: parseLocale(language, 'PascalCase'),
          hitI18nCode: parseLocale(language, 'snake_case'),
          longDesignation: parseDesignation(language, 'long'),
          shortDesignation: parseDesignation(language, 'short'),
        });
      });
      return companyLanguages;
    },
  },

  actions: {
    async initConfigurations() {
      /**
       * Used to store all the configs fetched during application startup in the store
       */
      this.initCustomButtons();
      this.initCustomConfigs();
      return ActionService.get('config/global').then((res) => {
        Object.entries(res.data).forEach(([key, value]) => {
          this.configurations[key] = value;
        });
      });
    },

    /**
     * We store the defined custom buttons in the store to avoid one request each time a view is loaded
     * The custom buttons are universal for all the users and not user specific
     */
    initCustomButtons() {
      DataService.read('config', {
        attributes: 'name,value',
        filters: {label: 'eq.custom.button'},
      }).then((res) => {
        if (res && res.data) {
          res.data.forEach((but) => {
            this.customButtons[but['name'].toLowerCase()] = but.value;
          });
        }
      });
    },

    /**
     * We store the user specific table filters in the store to avoid making one
     * HTTP request to config each time a table is loaded
     */
    initCustomConfigs() {
      DataService.read('config', {
        attributes: 'label,value,name',
        filters: {
          or:
            '(label.ilike.*filters*,label.ilike.*sorting*,label.eq.user.favorites*,label.eq.user.panels.hidden)',
          user_id: `eq.${globals.$appContext.user.id}`,
        },
      }).then((res) => {
        if (res && res.data) {
          res.data.forEach((filter) => {
            if (filter['label'] === 'user.favorites') {
              this.favoriteScreens = filter['value'];
            } else if (filter['label'] === 'user.panels.hidden') {
              this.hiddenPanels[filter['name']] = filter['value'];
            } else {
              const configType = filter['label'].split('.')[2];
              if (configType === 'filters') {
                this.tableFilters[filter['label']] = filter['value'];
              } else if (configType === 'sorting') {
                this.tableSorting[filter['label']] = filter['value'];
              }
            }
          });
        }
      });
    },

    getConfiguration(configLabel, customDefaultValue = undefined) {
      /**
       * Used to get the actual value of a config with fallback to default value -> parameter or application default
       */
      if (configLabel in this.configurations) {
        return this.configurations[configLabel];
      }
      return customDefaultValue !== undefined
        ? customDefaultValue
        : getDefaultValue(configLabel);
    },

    /**
     * Returns the list of custom buttons that have been stored during loading process
     */
    getCustomButtons(view) {
      const viewName = view.toLowerCase();
      return this.customButtons?.[viewName] ?? [];
    },

    /**
     * Returns the dictionary with the stored filters for the given table
     */
    getTableFilters(table) {
      return this.tableFilters?.[`${table}.filters`] ?? [];
    },

    /**
     * Returns the dictionary that stores the sorting value for the given table
     */
    getTableSorting(table) {
      const test = this.tableSorting?.[`${table}.sorting`] ?? {};
      return this.tableSorting?.[`${table}.sorting`] ?? {};
    },

    /**
     * Stores the new value of the filters in the store and in the database
     */
    updateTableFilters(table, filters) {
      const filterName = `${table}.filters`;
      const dbFilter = {
        label: `eq.${filterName}`,
        user_id: `eq.${globals.$appContext.user.id}`,
      };
      if (Object.keys(filters).length === 0) {
        DataService.delete('config', dbFilter);
        delete this.tableFilters[filterName];
      } else {
        DataService.upsert('config', {
          id: HitUUIDUtils.generate(),
          label: filterName,
          user_id: globals.$appContext.user.id,
          visible: false,
          customisable: true,
          datatype: 'JSON',
          value: filters,
          global: false,
        });
        this.tableFilters[filterName] = filters;
      }
    },

    /**
     * Stores the new value of the sort property and order in the database
     */
    updateTableSorting(table, sorting) {
      const sortingName = `${table}.sorting`;
      this.tableSorting[sortingName] = sorting;
      DataService.delete('config', {label: `eq.${sortingName}`}).then(() => {
        if (sorting?.property && sorting?.order) {
          DataService.create('config', {
            id: HitUUIDUtils.generate(),
            label: sortingName,
            user_id: globals.$appContext.user.id,
            visible: false,
            customisable: true,
            datatype: 'JSON',
            value: sorting,
            global: false,
          });
        }
      });
    },

    updateHiddenConfiguration(configLabel, newValue, forceIsDefault = false) {
      /**
       * Function used to update configurations that have the visible flag set to false (collapsed, columns, pagination)
       */
      if (newValue === getDefaultValue(configLabel) || forceIsDefault) {
        delete this.configurations[configLabel];
        return DataService.delete('config', {
          label: `eq.${configLabel}`,
          user_id: `eq.${globals.$keycloak.tokenParsed.sub}`,
        });
      } else if (configLabel in this.configurations) {
        this.configurations[configLabel] = newValue;
        return DataService.update(
          'config',
          {
            label: `eq.${configLabel}`,
            user_id: `eq.${globals.$keycloak.tokenParsed.sub}`,
          },
          {value: newValue}
        );
      } else {
        this.configurations[configLabel] = newValue;
        return DataService.create('config', {
          id: uuidv4(),
          label: configLabel,
          user_id: globals.$keycloak.tokenParsed.sub,
          visible: false,
          customisable: true,
          datatype: getConfigDatatype(configLabel),
          value: newValue,
          global: true,
        });
      }
    },

    async updateVisibleConfiguration(configLabel, newValue) {
      /**
       * Updates the config value for configs that have the visible flag set to true (locales, etc)
       */
      if (configLabel in this.configurations) {
        this.configurations[configLabel] = newValue;
      }
      return axios
        .post(
          globals.$appContext.actionUrl +
            `config/single?config_label=${configLabel}`,
          JSON.stringify({new_value: newValue}),
          {
            headers: {
              Authorization: 'Bearer ' + globals.$keycloak.token,
              'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
            },
          }
        )
        .catch(() => {
          console.error(
            'An error happened during the update of the following config : ',
            configLabel
          );
        });
    },
    async deleteConfiguration(configLabel) {
      const auth = {
        Authorization: 'Bearer ' + globals.$keycloak.token,
        'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
      };
      await axios.delete(
        `${globals.$appContext.actionUrl}config/single?config_label=${configLabel}`,
        {headers: auth}
      );
      const defaultValue = await axios.get(
        `${globals.$appContext.actionUrl}config/single?config_label=${configLabel}`,
        {headers: auth}
      );
      if (defaultValue.status === 200) {
        this.configurations[configLabel] = defaultValue.data.value;
      }
    },

    /**
     * Sets the value as company default
     */
    async setAsDefault(configLabel, value) {
      const filters = {
        label: `eq.${configLabel}`,
        user_id: 'is.null',
      };
      return DataService.update('config', filters, {
        value: value,
      });
    },

    /**
     * Returns the flag to check if the sync from Hit-Office is activated for this entity
     */
    hasSyncActivated(entityName) {
      return this.getConfiguration(`sync.${entityName}.active`, false);
    },

    getTablePage(tableName) {
      return this.tablePageConfigurations[tableName] ?? 0;
    },

    setTablePage(tableName, selectedPage) {
      this.tablePageConfigurations[tableName] = selectedPage;
    },

    updateFavoritesList(newFavoritesList) {
      this.favoriteScreens = newFavoritesList;
    },

    getHiddenPanels(viewName) {
      return this.hiddenPanels[viewName] ?? [];
    },

    async updateHiddenPanels(viewName, newHiddenPanels) {
      if (newHiddenPanels.length > 0) {
        if (this.hiddenPanels[viewName]) {
          this.hiddenPanels[viewName] = newHiddenPanels;
          return DataService.update(
            'config',
            {
              label: 'eq.user.panels.hidden',
              name: `eq.${viewName}`,
              user_id: `eq.${globals.$appContext.user.id}`,
            },
            {
              value: newHiddenPanels,
            }
          );
        } else {
          this.hiddenPanels[viewName] = newHiddenPanels;
          return DataService.create('config', {
            id: HitUUIDUtils.generate(),
            label: 'user.panels.hidden',
            user_id: globals.$appContext.user.id,
            name: viewName,
            visible: false,
            customisable: true,
            datatype: 'JSON',
            value: newHiddenPanels,
            global: false,
          });
        }
      } else {
        delete this.hiddenPanels[viewName];
        return DataService.delete('config', {
          label: 'eq.user.panels.hidden',
          name: `eq.${viewName}`,
          user_id: `eq.${globals.$appContext.user.id}`,
        });
      }
    },
  },
});
