import {
  Address,
  Country,
  DueDate,
  Equipment,
  Project,
  ProjectPart,
  Qualification,
  Resource,
  ResourceCategory,
  ResourceData,
  ResourcePackaging,
  ResourceSupplier,
  Staff,
  Unit,
  DayType,
  Activity,
  Stock,
  StandardText,
  PriceIndex,
  PriceIndexData,
} from '../../models';

export default {
  computed: {
    Address() {
      return Address;
    },
    Country() {
      return Country;
    },
    DueDate() {
      return DueDate;
    },
    Equipment() {
      return Equipment;
    },
    Project() {
      return Project;
    },
    ProjectPart() {
      return ProjectPart;
    },
    Resource() {
      return Resource;
    },
    ResourceCategory() {
      return ResourceCategory;
    },
    ResourceData() {
      return ResourceData;
    },
    ResourcePackaging() {
      return ResourcePackaging;
    },
    ResourceSupplier() {
      return ResourceSupplier;
    },
    Staff() {
      return Staff;
    },
    StandardText() {
      return StandardText;
    },
    Stock() {
      return Stock;
    },
    Unit() {
      return Unit;
    },
    Qualification() {
      return Qualification;
    },
    PriceIndex() {
      return PriceIndex;
    },
    DayType() {
      return DayType;
    },
    Activity() {
      return Activity;
    },
  },

  methods: {
    getModel(entityName) {
      const modelName =
        entityName.charAt(0).toUpperCase() + entityName.slice(1);
      return this[modelName];
    },
  },
};
