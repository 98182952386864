export class HitUtils {
  /**
   * Converts the given string in camel case to kebab-case (abCd -> ab-cd)
   */
  static camelToKebab(camel) {
    return camel.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  /**
   * Converts the given string in camel case to snake_case (abCd -> ab_cd)
   */
  static camelToSnake(camel) {
    return camel.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
  }

  /**
   * Converts the given kebab case to snake case (aB-Cd -> ab_cd)
   */
  static kebabToSnake(kebab) {
    return kebab.toLowerCase().replaceAll('-', '_');
  }

  /**
   * Converts the given snake case to kebab case (ab_cd -> ab-cd)
   */
  static snakeToKebab(snake) {
    return snake.toLowerCase().replaceAll('_', '-');
  }

  /**
   * Converts the given snake case to camel case (ab_cd -> abCd)
   */
  static snakeToCamel(snake) {
    let words = snake.split('_');
    return (
      words[0] +
      words
        .slice(1)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('')
    );
  }

  /**
   * Generates a random number with the specified number of digits
   */
  static randomNo(digits) {
    const min = Math.pow(10, digits - 1);
    const max = Math.pow(10, digits) - 1;
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  /**
   * Generates the designation for the standard entities. THe designations is
   * the concatenation of no and designations separated by a -. If a part does
   * not exist, the - is removed.
   */
  static createStandardDesignation(
    record,
    noKey = 'number',
    desKey = 'designation',
    separator = ' - '
  ) {
    if (!record) return null;
    return [record[noKey], record[desKey]]
      .filter((p) => p && p !== '')
      .join(separator);
  }

  static filtersToString(filtersObject) {
    const stringifyFilters = {};
    Object.entries(filtersObject)
      .filter(([_, value]) => value.length > 0)
      .forEach(([key, values]) => {
        if (values.length === 0) return;
        let queryString = '';
        if (typeof values === 'string') {
          stringifyFilters[key] = values;
        } else {
          values.forEach((val) => {
            queryString += `${val},`;
          });
          const filterParts = key.split('.');
          if (['or', 'and'].some((item) => filterParts.includes(item))) {
            stringifyFilters[key] = `(${queryString.slice(0, -1)})`;
          } else {
            stringifyFilters[key] = `${queryString.slice(0, -1)}`;
          }
        }
      });
    return stringifyFilters;
  }

  static fullLocationString(item) {
    const parts = [];
    if (item.street) parts.push(item.street + ' ' + (item?.houseNo || ''));
    if (item.postcode || item.city)
      parts.push((item?.postcode || '') + ' ' + (item?.city || ''));
    if (parts.length > 0) {
      return parts.join('\n');
    }
    return null;
  }

  static convertFileSize(sizeInKb) {
    if (sizeInKb < 1000) {
      return sizeInKb + ' B';
    } else if (sizeInKb < 1000000) {
      return (sizeInKb / 1000).toFixed(2) + ' kB';
    } else if (sizeInKb < 1000000000) {
      return (sizeInKb / 1000000).toFixed(2) + ' MB';
    } else if (sizeInKb < 1000000000000) {
      return (sizeInKb / 1000000000).toFixed(2) + ' GB';
    }
  }
}
