import { MyHttpManager } from './HttpManager';
import { enumValues, Form } from '../tools/Form';
import { Base64 } from 'js-base64';
import axios from 'axios';
// import * as process from "process";
// -----------------------------------------------------------------
//                           TO READ :
//    At the date of 27/09, there is only the Belgium companies
//            that're managed by our system backend
// -----------------------------------------------------------------
const BASE_URL = process.env.VUE_APP_ADMIN_API_BASE_URL;
export class ApiService {
    // Triger a value to update the DB with all news links about data freshly added
    static async updateDb() {
        try {
            const result = await MyHttpManager.post(BASE_URL + 'database/reload');
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }
    // Check VAT number
    static async checkIfVatNumberIsValid(myUser, countryCode = '', vatNumber = '', addInfoIntoObject = false) {
        vatNumber = vatNumber.trim();
        const restOfUrl = 'vat/check/' + vatNumber;
        const fullUrl = BASE_URL + restOfUrl;
        const token = this.generateAdminApiBase64Token();
        const config = {
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                Authorization: `Basic ${token}`,
            },
        };
        await axios.get(fullUrl, config).then((response) => {
            if (response.data.stateCompany === true) {
                if (addInfoIntoObject) {
                    Form.addContentInUserCompanyObject(myUser, enumValues.designationLegal, response.data.companyName);
                    Form.addContentInUserCompanyObject(myUser, enumValues.street, response.data.street);
                    Form.addContentInUserCompanyObject(myUser, enumValues.houseNumber, response.data.houseNumber);
                    // Form.addContentInUserCompanyObject(myUser, enumValues.boxNumber, result.data.boxNumber);
                    Form.addContentInUserCompanyObject(myUser, enumValues.postCode, response.data.postCode);
                    Form.addContentInUserCompanyObject(myUser, enumValues.city, response.data.city);
                    Form.addContentInUserCompanyObject(myUser, enumValues.checkVatNumberBeforeAddAccount, null, false);
                }
                return true;
            }
            else {
                return false;
            }
        });
        return false; //todo replace by catch
    }
    static async checkIfIndiaVatNumberIsValid(myUser, countryCode = '', vatNumber = '', addInfoIntoObject = false) {
        vatNumber = vatNumber.trim();
        const restOfUrl = 'vat/india/check/' + vatNumber;
        const fullUrl = BASE_URL + restOfUrl;
        const token = this.generateAdminApiBase64Token();
        const config = {
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                Authorization: `Basic ${token}`,
            },
        };
        await axios.get(fullUrl, config).then((response) => {
            if (response.data.status === 'Active') {
                if (addInfoIntoObject) {
                    Form.addContentInUserCompanyObject(myUser, enumValues.designationLegal, response.data.companyName);
                    Form.addContentInUserCompanyObject(myUser, enumValues.street, response.data.street);
                    Form.addContentInUserCompanyObject(myUser, enumValues.houseNumber, response.data.houseNumber);
                    Form.addContentInUserCompanyObject(myUser, enumValues.postCode, response.data.postCode);
                    Form.addContentInUserCompanyObject(myUser, enumValues.city, response.data.city);
                    Form.addContentInUserCompanyObject(myUser, enumValues.checkVatNumberBeforeAddAccount, null, false);
                }
                return true;
            }
            else {
                return false;
            }
        });
        return false; //todo replace by catch
    }
    static generateAdminApiBase64Token() {
        const concatCredentials = `${process.env.VUE_APP_ADMIN_API_BASIC_AUTH_USERNAME}:${process.env.VUE_APP_ADMIN_API_BASIC_AUTH_PASSWORD}`;
        return Base64.encode(concatCredentials);
    }
    static async getUserInfos(uid, referralCode) {
        let restOfUrl = 'mail/validation/' + uid;
        if (referralCode !== '') {
            restOfUrl += referralCode;
        }
        const fullUrl = BASE_URL + restOfUrl;
        const token = this.generateAdminApiBase64Token();
        const config = {
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                Authorization: `Basic ${token}`,
            },
        };
        try {
            return await MyHttpManager.post(fullUrl, {}, config);
        }
        catch (error) {
            throw new Error();
        }
    }
}
