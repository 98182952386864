<template>
  <hit-fast-mode-browser
    :label="labelToDisplay"
    :options="null"
    :entity-class="EnumerationValue"
    :value="valueToDisplay"
    :static-values="options"
    :inline-input="inlineInput"
    @change="handleChange"
  />
</template>

<script>
import {useI18n} from 'vue-i18n';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
import {HitFastModeBrowser} from '@hit/components';
import EnumerationValue from '../../../../models/data/enumeration';

export default {
  name: 'HitContainerEnumLabelSelect',
  components: {HitFastModeBrowser},
  setup() {
    const {t} = useI18n();
    return {t};
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    excludeKey: {
      type: String,
      required: false,
      default: null,
    },
    optionsToExcludeMapping: {
      type: Object,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
    },
    inlineInput: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Overrides the header prop of the container attribute if we want to display
     * another label then the standard one
     */
    label: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Set this flag to hide the label in case of a group input where the label
     * of the first input needs to be passed to the group input component
     */
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    EnumerationValue() {
      return EnumerationValue;
    },
    /**
     * To define the label we need to display, we have the following options (taken in this order)
     * 1. Label prop passed to this input
     * 2. Header value of the tableProperties config from the attribute
     * 3. Empty string is displayed
     */
    labelToDisplay() {
      if (this.hideLabel || this.inlineInput) return null;
      if (this.label) {
        return this.label;
      }
      const att = this.container.attributes[this.attribute];
      const trans = att?.tableProperties?.header ?? '';
      return this.t(trans);
    },
    options() {
      return HitOnlineEnums.getEnumValues(
        this.container.attributes[this.attribute].enumType,
        this.t
      );
    },
    valueToDisplay() {
      const value = this.container.multiple
        ? this.value
        : this.container.data[this.attribute];
      return value;
    },
    optionsToExclude() {
      if (
        this.excludeKey &&
        this.optionsToExcludeMapping &&
        this.excludeKey in this.optionsToExcludeMapping
      ) {
        const invalidOptions = this.optionsToExcludeMapping[this.excludeKey];
        return invalidOptions ? invalidOptions : [];
      }
      return [];
    },
  },

  methods: {
    handleChange(data) {
      if (data !== null && !(data instanceof this.EnumerationValue)) {
        return;
      }
      this.container.handleChange(this.attribute, data);
      this.$emit('set-label', data);
    },
    // TODO @j.even -> check where this has been used before and refactor to still use the events
    handleAdd(value) {
      const enumVal = this.options.find((i) => i.id === value);
      if (this.container.multiple) {
        this.$emit('change-label', enumVal);
      } else {
        this.container.handleChange(this.attribute, enumVal);
        this.$emit('set-label', enumVal);
      }
    },
    handleRemove(value) {
      this.$emit('remove', value);
    },
    handleClear() {
      if (this.container.multiple) {
        console.error('Impossible to add clear prop to multiple select');
      } else {
        this.container.handleChange(this.attribute, null);
      }
    },
  },
};
</script>
