import BaseModel from './BaseModel';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {Resource} from './index';

export default class Equipment extends BaseModel {
  constructor({
    id,
    number = undefined,
    designation = undefined,
    resource = undefined,
    active = true,
    tags = [],
    planning = false,
    serialNumber = undefined,
    chassisNumber = undefined,
    registrationNumber = undefined,
    memo = undefined,

    // We also need to add the key how it is stored in the db (we use the db column names)
    no = undefined,
  }) {
    super(id, number || no, designation);
    this.resource = resource ? new Resource(resource) : null;
    this.active = active;
    this.tags = tags;
    this.planning = planning;
    this.serialNumber = serialNumber;
    this.chassisNumber = chassisNumber;
    this.registrationNumber = registrationNumber;
    this.memo = memo;
  }

  static get fkName() {
    return 'equipment_id';
  }
  static get apiRoute() {
    return 'equipment';
  }
  static get listPageRoute() {
    return 'equipmentList';
  }
  static get detailPageRoute() {
    return 'equipmentDetail';
  }
  get fkName() {
    return Equipment.fkName;
  }
  get apiRoute() {
    return Equipment.apiRoute;
  }
  get detailPageRoute() {
    return Equipment.detailPageRoute;
  }
  get listPageRoute() {
    return Equipment.listPageRoute;
  }

  static get printTemplateType() {
    return 'equipment';
  }
  get printTemplateType() {
    return Equipment.printTemplateType;
  }

  static get attrsToStoreInForm() {
    return ['number', 'designation', 'resource'];
  }
  get attrsToStoreInForm() {
    return BaseModel.attrsToStoreInForm;
  }

  static get joinAttrsKey() {
    return ['number', 'designation', 'resource'];
  }

  static get listDisplayAttrs() {
    return this.getAttributes(['number', 'designation', 'active', 'tags']);
  }

  static get detailAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'resource',
      'tags',
      'planning',
      'active',
      'serialNumber',
      'chassisNumber',
      'registrationNumber',
      'memo',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      ['number', 'designation', 'active', 'tags'],
      ['resource']
    );
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-app.common.designation',
          maxWidth: '2fr',
        },
      }),
      resource: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        joinPath: ['resource!fk_equipment_cost_resource_id_resource'],
        formItemName: 'resource',
        fkToUpdate: 'cost_resource_id',
        tableProperties: {
          header: 'hit-app.common.cost-resource',
        },
      }),
      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
          maxWidth: '1fr',
        },
      }),
      planning: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.timeslot.planning-flag',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
      serialNumber: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'serial_no',
        tableProperties: {
          header: 'hit-app.equipment.serial-number',
          sharable: true,
        },
      }),
      chassisNumber: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'chassis_no',
        tableProperties: {
          header: 'hit-app.equipment.chassis-number',
          sharable: true,
        },
      }),
      registrationNumber: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'registration_no',
        tableProperties: {
          header: 'hit-app.equipment.registration-number',
          sharable: true,
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
    };
  }
}
