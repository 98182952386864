import EnumerationValue from '@hit/base/src/models/data/enumeration';

export const RESOURCE_CATEGORIES = {
  LABOUR: '1062440d-534f-46d9-8176-fd9582ae6202',
  MATERIAL: '232a8533-6549-4d32-acc4-ce4d974d7f53',
  MACHINE: '31df80bd-c1b0-4636-9a66-ef2f2ff8a7a2',
  CONSUMABLE: '4c0f3477-bc97-42c3-9b09-3ddc582539ac',
};

export class HitOnlineEnums {
  static JOURNAL_TYPE = [
    {id: 'SALE', key: 'hit-app.hit-journal-type.sale'},
    {id: 'PURCHASE', key: 'hit-app.hit-journal-type.purchase'},
    {id: 'FINANCIAL', key: 'hit-app.hit-journal-type.financial'},
    {id: 'GENERAL', key: 'hit-app.hit-journal-type.general'},
  ];

  static TRANSACTION_TYPE = [
    {id: 'GENERAL', key: 'hit-app.hit-transaction-type.general'},
    {id: 'INVOICE', key: 'hit-app.hit-transaction-type.invoice'},
    {id: 'CREDITNOTE', key: 'hit-app.hit-transaction-type.creditnote'},
    {id: 'FINANCIAL', key: 'hit-app.hit-transaction-type.financial'},
  ];

  static ACTION_STATUS = [
    {id: 'OPENED', key: 'hit-app.hit-action-status.opened'},
    {id: 'ASSIGNED', key: 'hit-app.hit-action-status.assigned'},
    {id: 'CLOSED', key: 'hit-app.hit-action-status.closed'},
  ];

  static ACTION_OPERATION = [
    {id: 'CREATE', key: 'hit-app.hit-action-operation.create'},
    {id: 'START', key: 'hit-app.hit-action-operation.start'},
    {id: 'STOP', key: 'hit-app.hit-action-operation.stop'},
    {id: 'CLOSE', key: 'hit-app.hit-action-operation.close'},
    {id: 'MESSAGE', key: 'hit-app.hit-action-operation.message'},
    {id: 'REMINDER', key: 'hit-app.hit-action-operation.reminder'},
    {id: 'ASSIGN', key: 'hit-app.hit-action-operation.assign'},
    {id: 'UNASSIGN', key: 'hit-app.hit-action-operation.unassign'},
    {id: 'PAUSE', key: 'hit-app.hit-action-operation.pause'},
    {id: 'RENAME', key: 'hit-app.hit-action-operation.rename'},
    {id: 'ADD_TAG', key: 'hit-app.hit-action-operation.add-tag'},
    {id: 'REMOVE_TAG', key: 'hit-app.hit-action-operation.remove-tag'},
    {id: 'MERGE', key: 'hit-app.hit-action-operation.merge'},
    {id: 'REOPEN', key: 'hit-app.hit-action-operation.reopen'},
    {
      id: 'CHANGE_DUE_DATE',
      key: 'hit-app.hit-action-operation.change-due-date',
    },
    {
      id: 'CHANGE_ASSIGNEE',
      key: 'hit-app.hit-action-operation.change-assignee',
    },
  ];

  static UNIT_CATEGORY = [
    {id: 'quantity', key: 'hit-app.hit-unit-category.quantity'},
    {id: 'length', key: 'hit-app.hit-unit-category.length'},
    {id: 'area', key: 'hit-app.hit-unit-category.area'},
    {id: 'volume', key: 'hit-app.hit-unit-category.volume'},
    {id: 'mass', key: 'hit-app.hit-unit-category.mass'},
    {id: 'time', key: 'hit-app.hit-unit-category.time'},
    {id: 'power', key: 'hit-app.hit-unit-category.power'},
  ];

  static GENDER = [
    {id: 'MALE', key: 'hit-app.hit-gender.male'},
    {id: 'FEMALE', key: 'hit-app.hit-gender.female'},
    {id: 'NOT_SELECTED', key: 'hit-app.hit-gender.not-selected'},
    {id: 'NOT_SPECIFIED', key: 'hit-app.hit-gender.not-specified'},
    {id: 'OTHER', key: 'hit-app.hit-gender.other'},
  ];

  static PROJECT_PART_STATUS = [
    {
      id: 'QUOTATION_CREATED',
      key: 'hit-app.hit-project-part-status.quotation-created',
    },
    {
      id: 'QUOTATION_IGNORED',
      key: 'hit-app.hit-project-part-status.quotation-ignored',
    },
    {
      id: 'QUOTATION_IN_PROGRESS',
      key: 'hit-app.hit-project-part-status.quotation-in-progress',
    },
    {
      id: 'QUOTATION_MILESTONE',
      key: 'hit-app.hit-project-part-status.quotation-milestone',
    },
    {
      id: 'QUOTATION_SENT',
      key: 'hit-app.hit-project-part-status.quotation-sent',
    },
    {
      id: 'QUOTATION_ACCEPTED',
      key: 'hit-app.hit-project-part-status.quotation-accepted',
    },
    {
      id: 'QUOTATION_REFUSED',
      key: 'hit-app.hit-project-part-status.quotation-refused',
    },
    {
      id: 'QUOTATION_CANCELLED',
      key: 'hit-app.hit-project-part-status.quotation-cancelled',
    },
    {id: 'WORK_CREATED', key: 'hit-app.hit-project-part-status.work-created'},
    {
      id: 'WORK_IN_PROGRESS',
      key: 'hit-app.hit-project-part-status.work-in-progress',
    },
    {
      id: 'WORK_MILESTONE',
      key: 'hit-app.hit-project-part-status.work-milestone',
    },
    {
      id: 'WORK_COMPLETED',
      key: 'hit-app.hit-project-part-status.work-completed',
    },
    {
      id: 'WORK_FINAL_INVOICE',
      key: 'hit-app.hit-project-part-status.work-final-invoice',
    },
    {id: 'WORK_ACCEPTED', key: 'hit-app.hit-project-part-status.work-accepted'},
  ];

  static PURCHASE_DOCUMENT_TYPE = [
    {
      id: 'PURCHASE_ORDER',
      key: 'hit-app.hit-purchase-document-type.purchase-order',
    },
    {
      id: 'PRICE_REQUEST',
      key: 'hit-app.hit-purchase-document-type.price-request',
    },
  ];

  static ITEM_TYPE = [
    {
      id: 'TITLE',
      key: 'title',
    },
    {
      id: 'COMMENT',
      key: 'comment',
    },
    {
      id: 'ITEM',
      key: 'item',
    },
    {
      id: 'RESOURCE',
      key: 'resource',
    },
  ];

  static RESOURCE_TYPE = [
    {
      id: 'TITLE',
      key: 'hit-app.hit-resource-type.title',
    },
    {
      id: 'RESOURCE',
      key: 'hit-app.hit-resource-type.resource',
    },
    {
      id: 'ITEM',
      key: 'hit-app.hit-resource-type.item',
    },
  ];

  static ACTIVITY_TARGET = [
    {id: 'PROJECT_PART', key: 'hit-base.common.project-part'},
    {id: 'EQUIPMENT', key: 'hit-base.common.equipment'},
  ];

  static COST_TYPE = [
    {id: 'STAFF_HOUR', key: 'hit-app.staff.staff-hours'},
    {id: 'EQUIPMENT_HOUR', key: 'hit-app.equipment.equipment-hours'},
    {id: 'COST', key: 'hit-app.common.costs'},
    {id: 'PURCHASE_TRANSACTION', key: 'hit-app.common.transaction'},
    {id: 'DELIVERY_NOTE', key: 'hit-app.common.delivery-note'},
    {id: 'STOCK_MOVEMENT', key: 'hit-app.common.stock-movement'},
  ];

  static getEnumValues(enumType, translateFn) {
    let values = HitOnlineEnums[enumType];
    return values.map((v) => new EnumerationValue(v.id, v.key, translateFn));
  }

  static getEnumValue(enumType, enumValueOrObject, translateFn) {
    const values = HitOnlineEnums[enumType];
    const valueToUse =
      typeof enumValueOrObject === 'string'
        ? enumValueOrObject
        : enumValueOrObject?.id;
    const val = values.find((v) => v.id === valueToUse);
    return val ? new EnumerationValue(val.id, val.key, translateFn) : undefined;
  }
}
