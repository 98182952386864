import BaseModel from '../BaseModel';
import {Staff} from '../index';
import MultiLanguageField from '../MultiLanguageField';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../../components';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';

export default class Form extends BaseModel {
  constructor({
    id,
    designation,
    author = undefined,
    finished = false,
    status = undefined,
    submissionDate = undefined,
    validationDate = undefined,
    injectionDate = undefined,
    formDefinitionDesignationFrBe = undefined,
    formDefinitionDesignationDeBe = undefined,
    formDefinitionDesignationNlBe = undefined,
    formDefinitionDesignationFrLu = undefined,
    formDefinitionDesignationDeLu = undefined,
    formDefinitionDesignationFrFr = undefined,
    formDefinitionDesignationDeDe = undefined,
    formDefinitionDesignationNlNl = undefined,
    formDefinitionDesignationEnGb = undefined,
    formDefinitionDesignationEnIn = undefined,
    formDefinitionDesignationMrIn = undefined,
    formDefinitionDesignationHiIn = undefined,
    linkedEntity = undefined,
  }) {
    super(id, '', designation);
    this.author = author ? new Staff(author) : null;
    this.finished = finished;
    this.status = status;
    this.submissionDate = submissionDate;
    this.validationDate = validationDate;
    this.injectionDate = injectionDate;
    this.formDefinitionDesignations = new MultiLanguageField({
      formDefinitionDesignationFrBe,
      formDefinitionDesignationDeBe,
      formDefinitionDesignationNlBe,
      formDefinitionDesignationFrLu,
      formDefinitionDesignationDeLu,
      formDefinitionDesignationFrFr,
      formDefinitionDesignationDeDe,
      formDefinitionDesignationNlNl,
      formDefinitionDesignationEnGb,
      formDefinitionDesignationEnIn,
      formDefinitionDesignationMrIn,
      formDefinitionDesignationHiIn,
    });
    this.formDefinitionDesignation = this.formDefinitionDesignations.value;
    this.linkedEntity = linkedEntity;
  }

  static get fkName() {
    return 'form_id';
  }
  static get apiRoute() {
    return 'form';
  }
  static get detailPageRoute() {
    return 'formItem';
  }
  static get listPageRoute() {
    return 'formItem';
  }
  get fkName() {
    return Form.fkName;
  }
  get apiRoute() {
    return Form.apiRoute;
  }
  get listPageRoute() {
    return Form.listPageRoute;
  }
  get detailPageRoute() {
    return Form.detailPageRoute;
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'author',
      'formDefinition',
      'linkedEntity',
      'designation',
      'formDate',
    ]);
  }

  static get joinAttrsKey() {
    return ['designation', 'formDefinition', 'author', 'status'];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'author',
      'formDefinition',
      'linkedEntity',
      'designation',
      'formDate',
    ]);
  }

  static extractLinkedEntityFromParent(data) {
    if (!data) return null;
    return HitUtils.createStandardDesignation(JSON.parse(data), 'no');
  }

  static get allAttributes() {
    return {
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.designation',
        },
      }),
      formDefinition: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_FORM_DEFINITION,
        {
          column: 'form_definition_id',
          joinPath: [
            'form_definition!fk_form_form_definition_id_form_definition!inner',
          ],
          tableProperties: {
            header: 'hit-base.common.form',
          },
        }
      ),
      linkedEntity: new HitContainerAttribute(ATTRIBUTE_TYPES.JSON_OBJECT, {
        column: 'data->meta->parent->>data',
        tableProperties: {
          header: 'hit-base.form.linked-entity',
        },
        transform: (parentData) => {
          return this.extractLinkedEntityFromParent(parentData);
        },
      }),
      author: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        column: 'author_id',
        joinPath: ['staff!fk_form_author_id_staff'],
        tableProperties: {
          header: 'hit-base.common.author',
        },
      }),
      finished: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN),
      status: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.status',
        },
      }),
      submissionDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'submission_date',
        tableProperties: {
          header: 'hit-app.form.submission-date',
        },
      }),
      validationDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'validation_date',
        tableProperties: {
          header: 'hit-app.form.validation-date',
        },
      }),
      injectionDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'injection_date',
        tableProperties: {
          header: 'hit-app.form.injection-date',
        },
      }),
      formDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'form_date',
        tableProperties: {
          header: 'hit-app.form.form-date',
        },
      }),
    };
  }

  static factory() {
    return new Form({});
  }

  static getMTORelations() {
    return {
      formDefinition: {
        childIdColumn: 'form_definition_id',
        childTableName: 'form_definition',
      },
      author: {
        childIdColumn: 'author_id',
        childTableName: 'staff',
      },
    };
  }

  getStatusTextKey() {
    switch (this.status) {
      case 0:
        return 'hit-base.common.in-progress';
      case 1:
        return 'hit-base.common.sent';
      case 2:
        return 'hit-base.common.validated';
      case 3:
        return 'hit-base.common.injected';
      default:
        return 'hit-base.common.unknown';
    }
  }
}
