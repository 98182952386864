import {BaseModel} from './index';
import MultiLanguageField from './MultiLanguageField';

export default class MultiLanguageDesignationModel extends BaseModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
  }) {
    const designations = new MultiLanguageField({
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    super(id, number, designations.value);
    this.designations = designations.values;
  }

  static get joinFilterColumns() {
    return [
      'no',
      this.attributeUserLocale('designation', true),
      this.attributeCompanyLocale('designation', true),
    ];
  }
  get joinFilterColumns() {
    return MultiLanguageDesignationModel.joinFilterColumns;
  }

  /**
   * Only join the necessary attributes for the designation
   */
  static get joinAttrsKeyDesignation() {
    return [
      'number',
      this.attributeUserLocale('designation', true),
      this.attributeCompanyLocale('designation', true),
    ];
  }
}
